import {
  CART_ADD_ITEM,
  CART_CLEAR_ITEMS,
  CART_DECREMENT_ITEM,
  CART_REMOVE_ITEM,
  CART_SAVE_SHIPPING_ADDRESS,
  CART_UPDATE_MODIFIERS,
  UPDATE_CART_ITEMS,
} from "../lib/Constants/Cart";

const stateDefault = {
  cart_items: [],
  shipping_address: {},
};

export const cartReducer = (state = stateDefault, action) => {
  switch (action.type) {
    case CART_ADD_ITEM:
      let newItem = action.payload ? action.payload : state.cart_items;

      // Check if there's an exact match (same _id and selectedModifiers)

      const existingItemIndex = state.cart_items.findIndex(
        (item) =>
          item._id === newItem._id &&
          item.selectedModifiers?.length ===
            newItem.selectedModifiers?.length &&
          item.selectedModifiers?.every(
            (modifier) => modifier && modifier.modifier_key
          )
      );
      if (existingItemIndex !== -1) {
        return {
          ...state,
          cart_items: state.cart_items.map((item, index) => {
            if (index === existingItemIndex) {
              return {
                ...item,
                quantity: item.quantity + newItem.quantity,
              };
            }
            return item;
          }),
        };
      } else {
        return {
          ...state,
          cart_items: [...state.cart_items, newItem],
        };
      }

    case CART_UPDATE_MODIFIERS:
      const { index: updateIndex, modifiers: newModifiers } = action.payload;
      return {
        ...state,
        cart_items: state.cart_items.map((item, i) =>
          i === updateIndex
            ? { ...item, selectedModifiers: newModifiers }
            : item
        ),
      };

    case CART_DECREMENT_ITEM:
      const { index: decrementIndex } = action.payload;
      const itemToDecrement = state.cart_items[decrementIndex];

      if (itemToDecrement.quantity > 1) {
        // If quantity is greater than 1, decrement it
        return {
          ...state,
          cart_items: state.cart_items.map((item, i) =>
            i === decrementIndex
              ? { ...item, quantity: item.quantity - 1 }
              : item
          ),
        };
      } else {
        // If quantity is 1 or less, remove the item from the cart
        return {
          ...state,
          cart_items: state.cart_items.filter((_, i) => i !== decrementIndex),
        };
      }
    case CART_REMOVE_ITEM:
      const { index: removeIndex } = action.payload;
      return {
        ...state,
        cart_items: state.cart_items.filter((_, i) => i !== removeIndex),
      };
    case CART_SAVE_SHIPPING_ADDRESS:
      return {
        ...state,
        shipping_address: action.payload,
      };
    case CART_CLEAR_ITEMS:
      return {
        ...state,
        cart_items: [],
        shipping_address: {},
      };
    case UPDATE_CART_ITEMS:
      return {
        ...state,
        cart_items: action.payload,
      };
    default:
      return state;
  }
};
