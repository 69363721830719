import {
  GET_LIST_BLOG_FAILED,
  GET_LIST_BLOG_REQUEST,
  GET_LIST_BLOG_SUCCESS,
  GET_POST_FAILED,
  GET_POST_REQUEST,
  GET_POST_SUCCESS,
} from "../lib/Constants/Blog";

const stateDefault = {
  listBlogs: [],
  loading: false,
  success: false,
  error: false,
  post: {},
};

export const GetAllBlogReducer = (state = stateDefault, action) => {
  switch (action.type) {
    case GET_LIST_BLOG_REQUEST:
      return {
        loading: true,
        success: false,
      };
    case GET_LIST_BLOG_SUCCESS:
      return {
        success: true,
        loading: false,
        listBlogs: action.payload,
      };
    case GET_LIST_BLOG_FAILED:
      return { ...state };
    default:
      return state;
  }
};

export const GetSinglePost = (state = stateDefault, action) => {
  switch (action.type) {
    case GET_POST_REQUEST:
      return {
        loading: true,
        success: false,
      };
    case GET_POST_SUCCESS:
      return {
        loading: false,
        success: true,
        post: action.payload,
      };
    case GET_POST_FAILED:
      return { ...state, error: true };
    default:
      return state;
  }
};
