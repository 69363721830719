import Swal from "sweetalert2";
import moment from 'moment';

const FormatDate = (dateString) => {
  const date = new Date(dateString);

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: false, // Use 24-hour format
  };

  return date.toLocaleDateString("vi-VN", options);
};

const FormatCurrencyToVND = (price) =>
  new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
  }).format(price);

function CalculatePriceDisplay(product) {
  const { itemSaleRate, item_price, itemIsOnSale } = product;
  if (itemSaleRate > 0 && itemIsOnSale === true) {
    const salePrice = (item_price * itemSaleRate) / 100;
    return Math.round(item_price - salePrice);
  } else {
    return item_price;
  }
}

function CalculatePrice(product) {
  const { itemSaleRate, item_price, itemIsOnSale, selectedModifiers } = product;

  // Calculate base price
  let basePrice = parseFloat(item_price);

  if (itemIsOnSale && itemSaleRate > 0) {
    const salePrice = (item_price * itemSaleRate) / 100;
    basePrice -= salePrice;
  }

  if (selectedModifiers && Array.isArray(selectedModifiers)) {
    selectedModifiers.forEach((modifier) => {
      const modifierPrice = parseFloat(modifier.modifier_price);
      basePrice += modifierPrice;
    });
  }
  return basePrice;
}
const FormatUrl = (itemName) => {
  if (typeof itemName !== "string") {
    console.error("Item name should be a string.");
    return "";
  }
  // Convert to lowercase and replace accents
  itemName = itemName.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  return itemName.replace(/\s+/g, "-").toLowerCase();
};

// Toast Swal When Add To Cart Notification
const AddToCartNotification = (product, quantity) => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });
  Toast.fire({
    icon: "success",
    title: `Đã Thêm ${quantity} ${product.item_name} vào giỏ hàng`,
  });
};

const GetRedirectCode = (code) => {
  switch (code) {
    case "00":
      return "Giao dịch thành công";
    case "07":
      return "Trừ tiền thành công. Giao dịch bị nghi ngờ (liên quan tới lừa đảo, giao dịch bất thường).";
    case "09":
      return "Giao dịch không thành công do: Thẻ/Tài khoản của khách hàng chưa đăng ký dịch vụ InternetBanking tại ngân hàng.";
    case "10":
      return "Giao dịch không thành công do: Khách hàng xác thực thông tin thẻ/tài khoản không đúng quá 3 lần";
    case "11":
      return "Giao dịch không thành công do: Đã hết hạn chờ thanh toán. Xin quý khách vui lòng thực hiện lại giao dịch.";
    case "12":
      return "Giao dịch không thành công do: Thẻ/Tài khoản của khách hàng bị khóa.";
    case "13":
      return "Giao dịch không thành công do Quý khách nhập sai mật khẩu xác thực giao dịch (OTP). Xin quý khách vui lòng thực hiện lại giao dịch.";
    case "24":
      return "Giao dịch không thành công do: Khách hàng hủy giao dịch";
    case "51":
      return "Giao dịch không thành công do: Tài khoản của quý khách không đủ số dư để thực hiện giao dịch.";
    case "65":
      return "Giao dịch không thành công do: Tài khoản của Quý khách đã vượt quá hạn mức giao dịch trong ngày.";
    case "75":
      return "Ngân hàng thanh toán đang bảo trì.";
    case "79":
      return "Giao dịch không thành công do: KH nhập sai mật khẩu thanh toán quá số lần quy định. Xin quý khách vui lòng thực hiện lại giao dịch";
    case "99":
      return "Các lỗi khác (lỗi còn lại, không có trong danh sách mã lỗi đã liệt kê)";
    default:
      return "Mã lỗi không hợp lệ";
  }
};
export const DEFAULT_IMAGE_ITEM = "/images/defaultImage.png";
export const isNewPost = (timeStamp) => {
  const currentTime = moment();
  const postTime = moment(timeStamp, "MMM Do YY");
  const timeDifference = currentTime.diff(postTime, "days");
  return timeDifference <= 1; // Return true if post is less than or equal to one day old
};
export {
  CalculatePriceDisplay,
  FormatCurrencyToVND,
  CalculatePrice,
  FormatUrl,
  GetRedirectCode,
  AddToCartNotification,
  FormatDate,
};
