// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import {connect} from "react-redux"
// Utils
import { withRouter } from "react-router-dom";

// Style
import Style from "./view.module.scss";

// Components
import BlogList from "../components/BlogList";
import { _updatePageTitleAndDescription } from "../utils/systems";
// Service
import GetRequests from "../lib/Services/Users/GetRequests";
const { GetAllBlog } = GetRequests;
class Blog extends React.Component {
  componentDidMount() {
    this.props.GetAllBlog();

    _updatePageTitleAndDescription({
      description: "Đây là tất cả bài viết ăn vặt của chúng tôi.",
      title: "Tất cả bài viết",
    });
  }

  render() {
    return (
      <div className={`${Style.content_container} container`}>
        <h1 className={Style.heading}>Bài viết ăn uống</h1>
        <p className={Style.description}>Chia sẻ địa điểm ăn uống ngon nhất.</p>
        <BlogList allBlog={this.props.allBlog} />
      </div>
    );
  }
}
const getStateToProps = (state) => {
  return {
    allBlog: state.allBlog,
  };
};

const getDispatchToProps = {
  GetAllBlog,
};
export default connect(getStateToProps, getDispatchToProps)(withRouter(Blog));
