import React, { Component } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Style from "./style.module.scss";
import Img from "react-cool-img";
import Navigation from "swiper/modules/navigation/navigation";
import {
  AddToCartNotification,
  CalculatePrice,
  DEFAULT_IMAGE_ITEM,
  FormatCurrencyToVND,
  FormatUrl,
} from "../../../lib/Functions";
import { connect } from "react-redux";
import { Users } from "../../../lib/Services";
import OvButton from "../../../fields/Button";
const { addToCart } = Users.PostRequests;
// Import Swiper styles

class ProductSuggestion extends Component {
  handleAddToCart = (product, quantity) => {
    AddToCartNotification(product, quantity);
    this.props.addToCart(product, quantity);
  };
  render() {
    const { allItems } = this.props;
    return (
      <div className={`${Style.ProductSuggestion} container mt-4`}>
        <h2>Sản phẩm liên quan</h2>
        <Swiper
          modules={[Navigation]}
          navigation
          slidesPerView={2}
          spaceBetween={10}
          breakpoints={{
            576: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            992: {
              slidesPerView: 3,
            },
            1200: {
              slidesPerView: 4,
            },
          }}
        >
          {allItems?.map((product, i) => {
            if (!product.isPublishedProduct) {
              return null;
            }
            const isAvailableImage =
              Array(product.thumbnail_image).length > 0 &&
              product.thumbnail_image !== undefined
                ? product.thumbnail_image[0]
                : DEFAULT_IMAGE_ITEM;
            const outOfStock = product.itemIsInStock;
            return (
              <SwiperSlide key={i}>
                <div className="swiper-slide-content">
                  <div className={`${Style.card} card`}>
                    <Img
                      lazy={true}
                      debounce={1000}
                      src={isAvailableImage}
                      className={`${Style.zoomEffect} card-img-top `}
                      alt="Product Food Street"
                    />
                    {product.itemIsOnSale === true &&
                      product.itemSaleRate > 0 && (
                        <div className={`${Style.card_discount} fw-bold`}>
                          -{product.itemSaleRate}%
                        </div>
                      )}
                    <div className="card-body">
                      <a
                        href={`/product/${FormatUrl(product.item_name)}?_id=${
                          product._id
                        }`}
                        className={`${Style.card_title} card-title fs-6 mb-5`}
                      >
                        {product.item_name}
                      </a>
                      <p className="card-text text-start fs-6">
                        <span className={Style.card_price}>
                          {FormatCurrencyToVND(CalculatePrice(product))}
                        </span>

                        <span className={Style.card_price_origin}>
                          {product.itemIsOnSale === true &&
                            FormatCurrencyToVND(product.item_price)}
                        </span>
                      </p>
                      <OvButton
                        name="Add to cart"
                        status={outOfStock ? "active" : "inactive"}
                        className={Style.addToCart}
                        onClick={() => this.handleAddToCart(product, 1)}
                      >
                        {outOfStock ? "đặt món" : "hết hàng"}
                      </OvButton>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    );
  }
}
const mapDispatchToProps = {
  addToCart,
};
export default connect(null, mapDispatchToProps)(ProductSuggestion);
