const isLocal = () => {
  const { NODE_ENV } = process.env;
  return NODE_ENV === "development";
};

const DEV_BASE_URL = "http://localhost:8080";
const PROD_BASE_URL = process.env.REACT_APP_PROD_URL;
const BASE_URL = isLocal() ? DEV_BASE_URL : PROD_BASE_URL;
// GET METHODS
const GET_ALL_MODIFIERS = `${BASE_URL}/getAllModifier`;
const GET_ALL_ITEM = `${BASE_URL}/getAllItem`;
const GET_ALL_GROUP_MODIFIERS = `${BASE_URL}/getAllModifyGroup`;
const GET_ALL_CATEGORY = `${BASE_URL}/getAllCategory`;
const GET_SINGLE_ITEM = `${BASE_URL}/GetItem`;
const GET_ALL_BLOG = `${BASE_URL}/getAllBlog`;
const GET_POST = `${BASE_URL}/GetBlog`;
const GET_ORDER_BY_ID = `${BASE_URL}/getOrder`;
const GET_ITEM_BY_IDS = `${BASE_URL}/GetItemInStock`
// POST METHODS
const CREATE_ORDER_PAYMENT_VNPAY = `${BASE_URL}/create_payment_url`;
const CREATE_ORDER_FOR_CLIENT = `${BASE_URL}/CreateOrder`;
const SEND_EMAIL_TO_CLIENT = `${BASE_URL}/sendEmail`;
const GET_MODIFY_GROUP = `${BASE_URL}/getModifierGroup`;
const UPDATE_ORDER_BY_CLIENT = `${BASE_URL}/UpdateOrder`

const API_PATHS = {
  // GET
  GET_ALL_MODIFIERS,
  GET_ALL_ITEM,
  GET_ALL_GROUP_MODIFIERS,
  GET_ALL_CATEGORY,
  GET_SINGLE_ITEM,
  GET_MODIFY_GROUP,
  GET_ITEM_BY_IDS,
  // POST
  CREATE_ORDER_PAYMENT_VNPAY,
  CREATE_ORDER_FOR_CLIENT,
  GET_ALL_BLOG,
  GET_POST,
  SEND_EMAIL_TO_CLIENT,
  GET_ORDER_BY_ID,
  UPDATE_ORDER_BY_CLIENT
};
export default API_PATHS;