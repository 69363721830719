import React, { Component } from "react";
import { CheckoutInformation } from "../components/CheckoutModules";
import Style from "./view.module.scss";
import { _updatePageTitleAndDescription } from "../utils/systems";

class CheckoutScreen extends Component {
  componentDidMount() {

    _updatePageTitleAndDescription({
      description: "Trang thanh toán.",
      title: "Thanh toán đơn hàng",
    });
  }
  render() {
    return (
      <div className={`${Style.FixContainer} container`}>
        <CheckoutInformation />
      </div>
    );
  }
}

export default CheckoutScreen;
