import React, { Component } from "react";
import Style from "./style.module.scss";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Users } from "../../../lib/Services";
import {
  CalculatePrice,
  DEFAULT_IMAGE_ITEM,
  FormatCurrencyToVND,
  FormatUrl,
} from "../../../lib/Functions";
import OvButton from "../../../fields/Button";
const { decrementCartItem, addToCart, removeFromCart } = Users.PostRequests;


class CartInformation extends Component {
  state = { successCheckout: false };
  calculateSubtotal(product) {
    return CalculatePrice(product) * product.quantity;
  }
  calculateTotal() {
    const { cart_items = [] } = this.props.cart;
    const totalItemPrice = cart_items.reduce((total, product) => {
      return total + this.calculateSubtotal(product);
    }, 0);
    return FormatCurrencyToVND(totalItemPrice);
  }

  renderOrderCartItems() {
    const { cart_items = [] } = this.props.cart;
    return cart_items.map((product, index) => (
      <div className={Style.itemRow} key={index}>
        <div className={Style.itemThumb}>
          <img src={product.thumbnail_image[0] || DEFAULT_IMAGE_ITEM} alt={product.item_name} />
        </div>
        <div className={Style.itemName}>
          <Link
            className={Style.cartOrderItemName}
            to={{
              state: { singleProductData: product },
              pathname: `product/${FormatUrl(product.item_name)}?_id=${product._id}`,
            }}
          >
            {product.item_name}
          </Link>
        </div>
        <div className={Style.itemPrice}>
          {FormatCurrencyToVND(CalculatePrice(product))}
        </div>
        <div
          className={Style.itemDecrement}
          onClick={() => this.props.decrementCartItem(index)}
        >
          -
        </div>
        <div className={Style.itemQuantity}>{product.quantity}</div>
        <div
          className={Style.itemIncrement}
          onClick={() => this.props.addToCart(product, 1)}
        >
          +
        </div>
        <div
          className={Style.itemRemove}
          onClick={() => this.props.removeFromCart(index)}
        >
          Xoá
        </div>
      </div>
    ));
  }

  handleCartStatus = () => {
    const { cart_items = [] } = this.props.cart;
    if (Object.keys(cart_items).length === 0) {
      return (
        <div className={Style.emptyCartNotification}>
          <h5>Chưa có món ăn nào được thêm vào giỏ hàng</h5>
        </div>
      );
    } else {
      return this.renderOrderCartItems();
    }
  };
  render() {
    const { cart_items = [] } = this.props.cart;
    const countCartItem = Object.values(cart_items).reduce(
      (prevItem, currentItem) => {
        return prevItem + currentItem.quantity;
      },
      0
    );

    return (
      <div className={`${Style.CartContainer} container`}>
        <div className={"row"}>
          <div className="col-xs-12 col-sm-12 col-md-12">
            <div className="row">
              <div className="col-lg-9">
                <div className={Style.left}>
                  <div className={Style.CartTitle}>
                    <h5>Giỏ hàng</h5>
                    <span>({countCartItem} món ăn)</span>
                  </div>
                  <div className="row">
                    <div className="col-12">{this.handleCartStatus()}</div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <Link to="" className={Style.continueShoppingLink}>
                        Tiếp tục chọn món
                      </Link>
                    </div>
                    <div className="col-lg-6">
                      <div className={Style.OrderCartItemBottom}>
                        <div className={Style.subTotal}>
                          <p>Tạm tính:</p>
                          <span>{this.calculateTotal()}</span>
                        </div>
                        <div className={Style.TotalBill}>
                          <p>Tổng cộng:</p>
                          <span>{this.calculateTotal()}</span>
                        </div>
                        <div className={Style.CheckoutBtnBox}>
                          <Link
                            to="/checkout"
                            className={Style.CheckoutButtonLink}
                          >
                            <OvButton
                              name="checkout button"
                              className={Style.CheckoutButton}
                            >
                              Thanh toán
                            </OvButton>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className={Style.right}>bwqeqwdsadwadadaasadwadsadaw</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    cart: state.cart,
  };
};

const mapDispatchToProps = {
  decrementCartItem,
  addToCart,
  removeFromCart,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CartInformation);
