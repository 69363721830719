import React from "react";
import { Route } from "react-router-dom";
import { Footer, Header,  } from "../components/Layout";
import WithBreadcumbs from "../components/Layout/WithBreadcumbs";

class HomeRouter extends React.Component {
  ref = {};
  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(propsRoute) => {
          return (
            <React.Fragment>
              <Header />
              <WithBreadcumbs />
              <div className="MainContent">
                <Component {...propsRoute} />
              </div>
              <Footer />
            </React.Fragment>
          );
        }}
      />
    );
  }
}
export default HomeRouter;
