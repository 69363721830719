import React, { Component } from "react";
import Style from "./view.module.scss";
import { connect } from "react-redux";
import GetRequests from "../lib/Services/Users/GetRequests";
import PostRequests from "../lib/Services/Users/PostRequests";
import { AiOutlineArrowRight } from "react-icons/ai";
import {
  CalculatePrice,
  DEFAULT_IMAGE_ITEM,
  FormatCurrencyToVND,
  FormatDate,
} from "../lib/Functions";
import { _updatePageTitleAndDescription } from "../utils/systems";
import Swal from "sweetalert2";
const { GetOrderByID } = GetRequests;
const { ResetOrderDetailForClient, updateOrder } = PostRequests;
class TrackingOrder extends Component {
  state = { orderDetail: {}, orderID: "" };

  componentDidMount() {
    _updatePageTitleAndDescription({
      description: "Trang tra cứu đơn hàng sản phẩm.",
      title: "Tra cứu đơn hàng",
    });
  }

  handleResetOrderDetail = () => {
    this.setState({ orderID: "" });
    this.props.ResetOrderDetailForClient();
  };

  onGetOrderDetail = () => {
    this.props.GetOrderByID(this.state.orderID);
  };

  onChangeOrderID = (event) => {
    this.setState({ orderID: event.target.value });
  };
  calculateTotalItem(product) {
    const priceProduct = CalculatePrice(product) * product.quantity;
    return FormatCurrencyToVND(priceProduct);
  }

  handleCancelOrderStatus = () => {
    const { orderDetail = {} } = this.props.order;
    const {
      order_items = [],
      order_total = 0,
      shipping_address = {},
    } = orderDetail;
    const newPromotionDate = {
      ...shipping_address,
      order_status: "Hủy đơn hàng",
    };
    Swal.fire({
      title: "Xác nhận huỷ đơn hàng!",
      text: "Bạn có chắc chắn muốn hủy đơn hàng này?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Hủy ngay",
    }).then((result) => {
      if (result.isConfirmed) {
        const updatedOrderInfo = {
          order_items,
          order_total,
          shipping_address: newPromotionDate,
        };
        this.props.updateOrder(this.state.orderID, updatedOrderInfo);
        Swal.fire({
          title: "Huỷ đơn hàng thành công!",
          text: "Đơn hàng đã được hủy.",
          icon: "success",
        });
      }
    });
  };
  render() {
    const { orderID } = this.state;
    const { loading, orderDetail = {} } = this.props.order;
    const {
      order_items = [],
      order_total = 0,
      shipping_address = {},
    } = orderDetail;
    const {
      address = "",
      createdAt = "",
      email = "",
      fullName = "",
      note = "",
      order_status = "",
      paymentMethod = "",
      phoneNumber = "",
    } = shipping_address;
    const formattedDate = FormatDate(createdAt);
    const formatPaymentMethod =
      paymentMethod === "cashOnDelivery"
        ? "Thanh toán khi nhận hàng"
        : "Thanh toán banking";
    return (
      <div className={`${Style.TrackingOrderContainer} container`}>
        <div className="container-fluid">
          <div className="container">
            {Object.keys(orderDetail).length === 0 ? (
              <div style={{ textAlign: "center" }}>
                <img
                  className={Style.imageCover}
                  src="/images/tracking_order.png"
                  alt="tracking order"
                />
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Vui lòng nhập mã đơn hàng để tra cứu !"
                    aria-label="Vui lòng nhập mã đơn hàng để tra cứu "
                    aria-describedby="button-addon2"
                    value={orderID}
                    onChange={this.onChangeOrderID}
                  />
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    id="button-addon2"
                    onClick={this.onGetOrderDetail}
                  >
                    Tìm kiếm
                  </button>
                </div>
                {loading && (
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </div>
            ) : (
              <>
                <div className="d-flex justify-content-between align-items-center py-3">
                  <h2 className="h5 mb-0">
                    <p className="text-muted" /> Đơn hàng{" "}
                    <span>#{orderID}</span>
                  </h2>
                </div>
                {/* Nội dung chính */}
                <div className="row">
                  <div className="col-lg-8">
                    {/* Chi tiết */}
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="mb-3 d-flex justify-content-between">
                          <div>
                            <span className="me-3">{formattedDate}</span>
                            <span
                              className={`badge rounded-pill ${
                                order_status === "Hủy đơn hàng"
                                  ? "bg-danger"
                                  : "bg-info"
                              }`}
                              style={{ textTransform: "uppercase" }}
                            >
                              {order_status}
                            </span>
                          </div>
                          <div className="d-flex align-items-center">
                            <span className="badge bg-success rounded-pill">
                              {formatPaymentMethod}
                            </span>
                          </div>
                        </div>
                        <table className="table table-borderless">
                          <thead>
                            <th>Sản phẩm</th>
                            <th>Số lượng</th>
                            <th className="text-end">Giá sản phẩm</th>
                          </thead>
                          <tbody>
                            {order_items?.map((item) => {
                              const { selectedModifiers = [] } = item;
                              const isAvailableImage =
                                Array(item.thumbnail_image).length > 0 &&
                                item.thumbnail_image !== undefined
                                  ? item.thumbnail_image[0]
                                  : DEFAULT_IMAGE_ITEM;
                              return (
                                <tr key={item._id}>
                                  <td>
                                    <div className="d-flex mb-2">
                                      <div className="flex-shrink-0">
                                        <img
                                          src={isAvailableImage}
                                          alt={item.item_name}
                                          width={35}
                                          className="img-fluid"
                                        />
                                      </div>
                                      <div className="flex-lg-grow-1 ms-3">
                                        <h6 className="small mb-0">
                                          <p className="text-reset fs-6">
                                            {item.item_name}
                                          </p>
                                        </h6>
                                        {selectedModifiers.length > 0 &&
                                          selectedModifiers.map(
                                            (modifier, index) => {
                                              return (
                                                <span
                                                  className="small"
                                                  key={index}
                                                >
                                                  {index > 0 && ", "}
                                                  {modifier.modifier_name}
                                                </span>
                                              );
                                            }
                                          )}
                                      </div>
                                    </div>
                                  </td>
                                  <td>{item.quantity}</td>
                                  <td className="text-end">
                                    {this.calculateTotalItem(item)}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                          <tfoot>
                            {/* <tr>
                          <td colSpan={2}>Tổng phụ</td>
                          <td className="text-end">$159,98</td>
                        </tr> */}
                            {/* <tr>
                          <td colSpan={2}>Vận chuyển</td>
                          <td className="text-end">$20.00</td>
                        </tr> */}
                            {/* <tr>
                          <td colSpan={2}>Giảm giá (Mã: NEW YEAR)</td>
                          <td className="text-danger text-end"></td>
                        </tr> */}
                            <tr className="fw-bold">
                              <td colSpan={2}>TỔNG</td>
                              <td className="text-end">
                                {FormatCurrencyToVND(order_total)}
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    {/* Ghi chú của khách hàng */}
                    <div className="card mb-4">
                      <div className="card-body">
                        <h3 className="h6">Ghi chú của khách hàng</h3>
                        <p>{note}</p>
                      </div>
                    </div>
                    <div className="card mb-4">
                      {/* Thông tin vận chuyển */}
                      <div className="card-body">
                        <h3 className="h6">Thông tin vận chuyển</h3>

                        <hr />
                        <h3 className="h6">Địa chỉ</h3>
                        <address>
                          <strong>{fullName}</strong>
                          <br />
                          {address}
                          <br />
                          <abbr title="Email">Email:</abbr> {email}
                          <br />
                          <abbr title="Phone">Phone:</abbr> {phoneNumber}
                        </address>
                      </div>
                    </div>
                    {order_status === "Đang chờ xác nhận" && (
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={this.handleCancelOrderStatus}
                      >
                        Huỷ đơn hàng
                      </button>
                    )}
                  </div>
                </div>
                <p
                  style={{
                    color: "blue",
                    cursor: "pointer",
                    width: "fit-content",
                  }}
                  onClick={this.handleResetOrderDetail}
                >
                  <AiOutlineArrowRight /> Tra cứu đơn hàng khác
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    order: state.order,
    updateOrder: state.updateOrder,
  };
};

const mapDispatchToProps = {
  GetOrderByID,
  ResetOrderDetailForClient,
  updateOrder,
};
export default connect(mapStateToProps, mapDispatchToProps)(TrackingOrder);
