import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Users } from "../../lib/Services";
import { RawHTML } from "../BlogList/functions";
import Style from "./style.module.scss";
import { _updatePageTitleAndDescription } from "../../utils/systems";
import NotFound from "../../views/NotFound";
const { GetSinglePost } = Users.GetRequests;
class Post extends Component {
  componentDidMount() {
    const { location } = this.props;
    const pathname = location.pathname;
    const lastSlashIndex = pathname.lastIndexOf("/");
    const slug = pathname.substring(lastSlashIndex + 1);
    if (slug) {
      this.props.GetSinglePost(slug);
    }
  }
  componentDidUpdate = (prevProps) => {
    const { singlePost = {} } = this.props;
    const { post = {} } = singlePost;
    const { title_blog } = post;
    if (singlePost && title_blog !== prevProps.post?.title_blog) {
      _updatePageTitleAndDescription({
        description: "Nơi share các món ăn vặt phổ biến.",
        title: `${title_blog} | Ăn vặt Blog`,
      });
    }
  };
  render() {
    const { singlePost = {} } = this.props;
    const { loading, post = {}, error } = singlePost;
    const { body_blog, title_blog = "" } = post;
    if (error) {
      return <NotFound />;
    }
    return (
      <div className={Style.container}>
        {loading ? (
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          <>
            <h1 className={Style.post_title}>{title_blog}</h1>
            <RawHTML className={Style.post_content} nl2br={false}>
              {body_blog}
            </RawHTML>
          </>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    singlePost: state.singlePost,
  };
};

const mapDispatchToProps = {
  GetSinglePost,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Post));
