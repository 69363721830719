import API_PATHS from "../API_Paths";
import axios from "axios";
import {
  GET_ALL_CATEGORY,
  GET_ALL_ITEM,
  GET_ALL_MODIFIERS,
  GET_SINGLE_ITEM_FAILED,
  GET_SINGLE_ITEM_SUCCESS,
  GET_SINGLE_REQUEST,
} from "../../Constants/Product";
import {
  GET_LIST_BLOG_FAILED,
  GET_LIST_BLOG_REQUEST,
  GET_LIST_BLOG_SUCCESS,
  GET_POST_FAILED,
  GET_POST_REQUEST,
  GET_POST_SUCCESS,
} from "../../Constants/Blog";
import {
  ORDER_DETAILS_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
} from "../../Constants/Order";

const GetAllModifiers = () => (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .get(API_PATHS.GET_ALL_MODIFIERS)
      .then(({ data }) =>
        resolve(
          dispatch({
            type: GET_ALL_MODIFIERS,
            payload: typeof data === "string" ? JSON.parse(data) : data,
          })
        )
      )
      .catch(reject);
  });

const GetAllItems = () => async (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .get(API_PATHS.GET_ALL_ITEM)
      .then(({ data }) => {
        resolve(
          dispatch({
            type: GET_ALL_ITEM,
            payload: typeof data === "string" ? JSON.parse(data) : data,
          })
        );
      })
      .catch(reject);
  });

const GetAllCategories = () => (dispatch) =>
  new Promise((resolve, reject) => {
    axios
      .get(API_PATHS.GET_ALL_CATEGORY)
      .then(({ data }) =>
        resolve(
          dispatch({
            type: GET_ALL_CATEGORY,
            payload: typeof data === "string" ? JSON.parse(data) : data,
          })
        )
      )
      .catch(reject);
  });

const GetSingleItem = (_id) => async (dispatch) => {
  dispatch({ type: GET_SINGLE_REQUEST });
  try {
    const { data } = await axios.get(`${API_PATHS.GET_SINGLE_ITEM}?_id=${_id}`);
    dispatch({ type: GET_SINGLE_ITEM_SUCCESS, payload: data });
    return data;
  } catch (err) {
    dispatch({ type: GET_SINGLE_ITEM_FAILED });
    console.log("Error getting single item", err);
  }
};

const GetAllBlog = () => async (dispatch) => {
  dispatch({ type: GET_LIST_BLOG_REQUEST });
  try {
    const { data } = await axios.get(`${API_PATHS.GET_ALL_BLOG}`);
    dispatch({ type: GET_LIST_BLOG_SUCCESS, payload: data });
  } catch (err) {
    dispatch({ type: GET_LIST_BLOG_FAILED });
    console.log("Error All Blog Failed", err);
  }
};

const GetSinglePost = (slug) => async (dispatch) => {
  dispatch({ type: GET_POST_REQUEST });
  try {
    const { data } = await axios.get(`${API_PATHS.GET_POST}?slug=${slug}`);
    dispatch({ type: GET_POST_SUCCESS, payload: data });
  } catch (err) {
    dispatch({ type: GET_POST_FAILED });
    console.log("Error Post Failed", err);
  }
};
const GetOrderByID = (orderID) => async (dispatch) => {
  dispatch({ type: ORDER_DETAILS_REQUEST });
  try {
    const { data } = await axios.get(
      `${API_PATHS.GET_ORDER_BY_ID}?orderID=${orderID}`
    );
    dispatch({ type: ORDER_DETAILS_SUCCESS, payload: data });
  } catch (err) {
    dispatch({ type: ORDER_DETAILS_FAIL });
    console.log("Error Order Failed", err);
  }
};

const GetRequests = {
  GetAllModifiers,
  GetAllItems,
  GetAllCategories,
  GetSingleItem,
  GetAllBlog,
  GetSinglePost,
  GetOrderByID,
};
export default GetRequests;
