import React, { Component } from "react";
import Style from "./style.module.scss";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  CalculatePrice,
  FormatCurrencyToVND,
  GetRedirectCode,
} from "../../../lib/Functions";
import { Users } from "../../../lib/Services";
const { CreateOrderForClient, sendEmailToClient } = Users.PostRequests;
class SuccessCheckout extends Component {
  constructor(props) {
    super(props);
    const { location } = props;
    const { state } = location;
    const { vnp_ResponseCode } = state || "";
    this.state = {
      successCheckout: false,
      vnp_ResponseCode: vnp_ResponseCode || "",
    };
  }
  calculateSubtotal(product) {
    return CalculatePrice(product) * product.quantity;
  }

  calculateTotal = (formatPrice) => {
    const { cart_items = [] } = this.props.cart;
    const totalItemPrice = cart_items.reduce((total, product) => {
      return total + this.calculateSubtotal(product);
    }, 0);
    if (formatPrice === true) {
      return totalItemPrice;
    } else {
      return FormatCurrencyToVND(totalItemPrice);
    }
  };

  componentDidMount() {
    const { success } = this.props.orderCreate;
    this.setState({ successCheckout: success });

  }

  componentDidUpdate(prevProps) {
    const { orderInfo } = this.props.orderCreate;
    const { orderInfo: prevOrderInfo } = prevProps.orderCreate;
    // Check if orderInfo has changed
    if (orderInfo && orderInfo !== prevOrderInfo) {
      const { order_id = "", order_info = {} } = orderInfo;
      const { shipping_address, order_total = 0 } = order_info;
      const orderEmailInfo = {
        order_id,
        email: shipping_address?.email,
        fullName: shipping_address?.fullName,
        phoneNumber: shipping_address?.phoneNumber,
        address: shipping_address?.address,
        order_total: order_total,
      };
      // Call sendEmailToClient with updated order info
      sendEmailToClient(orderEmailInfo);
    } else {
      return console.log("It is okay, don't worry about that")
    }
  }
  handleStatusCheckoutPage = () => {
    const { successCheckout, vnp_ResponseCode } = this.state;

    if (successCheckout) {
      return <div>Cảm ơn bạn đã ủng hộ shop ạ.</div>;
    } else if (vnp_ResponseCode) {
      return GetRedirectCode(vnp_ResponseCode);
    } else {
      return <div>No Status Page</div>;
    }
  };
  render() {
    return (
      <div className={`${Style.SuccessCheckoutContainer} container`}>
        {this.handleStatusCheckoutPage()}
      </div>
    );
  }
}

const mapDispatchToProps = {
  CreateOrderForClient,
};
const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    orderCreate: state.orderCreate,
    orderPay: state.orderPay,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SuccessCheckout));
