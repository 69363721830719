import React, { Component } from "react";
import CategoriesDetail from "../components/CategoriesDetail.js";
import MobileCartBottom from "../components/Layout/MobileCartBottom/index.js";
import View from "./view.module.scss";
import { _updatePageTitleAndDescription } from "../utils/systems.js";

class Categories extends Component {

  constructor(props) {
    super(props);
    const { match } = props;
    this.state = {
      categoryKeyRedirect: match?.params.id || "",
    };
  }
  componentDidMount() {

    _updatePageTitleAndDescription({
      description: "Trang mục của sản món ăn.",
      title: "Danh mục món ăn",
    });
  }
  render() {
    return (
      <div  className={`${View.StyleCategoriesDiv} container`}>
        <CategoriesDetail
          categoryKeyRedirect={this.state.categoryKeyRedirect}
        />
        <MobileCartBottom />
      </div>
    );
  }
}

export default Categories;
