import React, { Component } from "react";
import Style from "./view.module.scss";
import {
  ProductDetails,
  ProductSuggestion,
} from "../components/SingleProductModules";
import GetRequests from "../lib/Services/Users/GetRequests.js";
import { connect } from "react-redux";
import MobileCartBottom from "../components/Layout/MobileCartBottom";
const { GetAllItems, GetSingleItem } = GetRequests;
class SingleProduct extends Component {
  constructor(props) {
    super(props);
    const { location } = props;
    const { state } = location || {};
    const { singleProductData } = state || {};
    this.state = {
      productData: singleProductData || {},
    };
  }
  componentDidMount() {
    this.props.GetAllItems();
  }
  render() {
    const allItemsData = Object.values(this.props.allItems);
    return (
      <div className={`${Style.StyleSingleProductDiv} container`}>
        <MobileCartBottom />
        <ProductDetails productData={this.state.productData} />
        <ProductSuggestion allItems={allItemsData} />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    allItems: state.AllItemReducer.allItems,
    singleItem: state.GetSingleItemReducer.singleItem,
  };
};
const mapDispatchToProps = {
  GetAllItems,
  GetSingleItem,
};
export default connect(mapStateToProps, mapDispatchToProps)(SingleProduct);
