import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Style from "./style.module.scss";
import OvButton from "../../../fields/Button";
import { connect } from "react-redux";
import {
  CalculatePrice,
  DEFAULT_IMAGE_ITEM,
  FormatCurrencyToVND,
  FormatUrl,
} from "../../../lib/Functions";
import { Users } from "../../../lib/Services";
import Swal from "sweetalert2";

const {
  addToCart,
  decrementCartItem,
  removeFromCart,
  CreateOrderPaymentVNPay,
  CreateOrderForClient,
  SaveShippingAddress,
  sendEmailToClient,
  handleGetCartItems
} = Users.PostRequests;
class CheckoutInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vnp_ResponseCode: "",
      shipping_address: {
        fullName: "",
        email: "",
        phoneNumber: "",
        address: "",
        note: "",
        paymentMethod: "cashOnDelivery",
        order_status: "Đang chờ xác nhận",
        createdAt: new Date(),
      },
    };
  }
  componentDidMount() {
    const { shipping_address } = this.props.cart;
    if (Object.keys(shipping_address).length > 0) {
      this.setState({
        shipping_address: {
          fullName: shipping_address.fullName,
          email: shipping_address.email,
          phoneNumber: shipping_address.phoneNumber,
          address: shipping_address.address,
          note: shipping_address.note,
          paymentMethod: shipping_address.paymentMethod,
          order_status: shipping_address.order_status,
          createdAt: shipping_address.createdAt,
        },
      });
    }
    const currentUrl = window.location.href;
    // Extract query parameters from the URL
    const urlSearchParams = new URLSearchParams(currentUrl);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.vnp_ResponseCode) {
      this.setState({
        vnp_ResponseCode: params.vnp_ResponseCode,
      });
    }
    this.props.handleGetCartItems()
  }
  componentDidUpdate(prevProps) {
    const { shipping_address = {}, cart_items = [] } = this.props.cart;
    const success_payment_vnPay = "00";
    const { history, orderCreate } = this.props;
    const { success, orderInfo = {} } = orderCreate;
    const { order_id = "", order_info = {} } = orderInfo;
    const { shipping_address: addressOrder, order_total = 0 } = order_info;
    if (this.state.vnp_ResponseCode === success_payment_vnPay) {
      this.props.CreateOrderForClient({
        shipping_address,
        order_items: cart_items,
        order_total: this.calculateTotal(true),
      });
    }
    if (success && success !== prevProps.orderCreate.success) {
      const orderEmailInfo = {
        order_id,
        email: addressOrder?.email,
        fullName: addressOrder?.fullName,
        phoneNumber: addressOrder?.phoneNumber,
        address: addressOrder?.address,
        order_total: order_total,
      };
      sendEmailToClient(orderEmailInfo);
      history.push("/checkout/status");
    }
    if (this.state.vnp_ResponseCode !== "") {
      history.push({
        pathname: "/checkout/status",
        state: { vnp_ResponseCode: this.state.vnp_ResponseCode || "" },
      });
    }
    const { redirect_payment_url } = this.props.orderPay;
    if (redirect_payment_url) {
      window.location.href = redirect_payment_url;
    }
  }
  calculateSubtotal(product) {
    return CalculatePrice(product) * product.quantity;
  }
  calculateTotal = (formatPrice) => {
    const { cart_items = [] } = this.props.cart;
    const totalItemPrice = cart_items.reduce((total, product) => {
      const productPrice = CalculatePrice(product);
      return total + productPrice * product.quantity;
    }, 0);

    if (formatPrice === true) {
      return totalItemPrice;
    } else {
      return FormatCurrencyToVND(totalItemPrice);
    }
  };

  handleChangeInput = (event) => {
    const { id, value } = event.target;
    this.setState((prevState) => ({
      shipping_address: {
        ...prevState.shipping_address,
        [id]: value,
      },
    }));
  };

  handlePaymentMethodChange = (event) => {
    const selectedPaymentMethod = event.target.value;
    this.setState((prevState) => ({
      shipping_address: {
        ...prevState.shipping_address,
        paymentMethod: selectedPaymentMethod,
      },
    }));
  };
  validateInputs = () => {
    const { fullName, phoneNumber, address, email } =
      this.state.shipping_address;

    const isFullNameValid = fullName.trim() !== "" && fullName.length > 5;
    const isEmailValid =
      /\S+@\S+\.\S+/.test(email) && email.endsWith("@gmail.com");
    const isPhoneNumberValid = /^\d{10}$/.test(phoneNumber.trim());
    const isAddressValid = address.trim() !== "" && address.length > 10;

    return (
      isFullNameValid && isEmailValid && isPhoneNumberValid && isAddressValid
    );
  };

  handleSubmit = () => {
    const { cart_items = [] } = this.props.cart;
    const { shipping_address = {} } = this.state;
    const { paymentMethod } = shipping_address;
    if (this.validateInputs()) {
      if (paymentMethod === "cashOnDelivery") {
        this.props.CreateOrderForClient({
          shipping_address,
          order_items: cart_items,
          order_total: this.calculateTotal(true),
        });
      } else if (paymentMethod === "banking") {
        this.props.SaveShippingAddress(shipping_address);
        this.props.CreateOrderPaymentVNPay(this.calculateTotal(true));
      } else {
        console.log("Please fill in all required fields.");
      }
    }
  };
  isFieldEmpty = (fieldValue) => {
    return fieldValue.trim() === "";
  };

  isPhoneNumberValid = (phoneNumber) => {
    return phoneNumber.replace(/\D/g, "").length === 10;
  };

  renderError = (fieldName, errorMessage) => {
    const fieldValue = this.state.shipping_address[fieldName];

    if (this.isFieldEmpty(fieldValue)) {
      return <div className={Style.invalid}>{errorMessage}</div>;
    }

    if (fieldName === "fullName" && fieldValue.trim().length <= 5) {
      return (
        <div className={Style.invalid}>
          Họ và tên phải có nhiều hơn 5 ký tự.
        </div>
      );
    }

    if (
      fieldName === "email" &&
      (!/\S+@\S+\.\S+/.test(fieldValue) || !fieldValue.endsWith("@gmail.com"))
    ) {
      return <div className={Style.invalid}>Email không hợp lệ .</div>;
    }

    if (fieldName === "phoneNumber" && !this.isPhoneNumberValid(fieldValue)) {
      return (
        <div className={Style.invalid}>
          Số điện thoại phải có ít nhất 10 chữ số.
        </div>
      );
    }

    if (fieldName === "address" && fieldValue.trim().length <= 10) {
      return (
        <div className={Style.invalid}>Địa chỉ phải có nhiều hơn 10 ký tự.</div>
      );
    }

    return null;
  };

  handleCheckoutStatus = (loading) => {
    const { cart_items = [] } = this.props.cart;
    if (Object.keys(cart_items).length > 0 && this.validateInputs()) {
      return "active";
    }
    if (loading) {
      return "loading";
    } else {
      return "inactive";
    }
  };
  renderOrderCartItems() {
    const { cart_items = [] } = this.props.cart;
    return cart_items.map((product, index) => {
      const { selectedModifiers = [] } = product;
      const isAvailableImage =
        Array(product.thumbnail_image).length > 0 &&
        product.thumbnail_image !== undefined
          ? product.thumbnail_image[0]
          : DEFAULT_IMAGE_ITEM;
      return (
        <div className={Style.itemRow} key={index}>
          <div className={Style.itemThumb}>
            <img src={isAvailableImage} alt={product.item_name} />
          </div>
          <div className={Style.itemName}>
            <Link
              className={Style.cartOrderItemName}
              to={{
                state: { singleProductData: product },
                pathname: `product/${FormatUrl(product.item_name)}?_id=${
                  product._id
                }`,
              }}
            >
              {product.item_name}
            </Link>
          </div>
          <div className={Style.itemModifiers}>
            {selectedModifiers.length > 0 && (
              <div className={Style.modifiers_item}>
                <p>Món thêm:</p>
                {selectedModifiers.map((modifier, index) => (
                  <div key={index}>
                    {index > 0 && ", "} {modifier.modifier_name}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className={Style.itemPrice}>
            {FormatCurrencyToVND(CalculatePrice(product))}
          </div>
          <div
            className={Style.itemDecrement}
            onClick={() => this.props.decrementCartItem(index)}
          >
            -
          </div>
          <div className={Style.itemQuantity}>{product.quantity}</div>
          <div
            className={Style.itemIncrement}
            onClick={() => this.props.addToCart(product, 1, selectedModifiers, index)}
          >
            +
          </div>
          <div
            className={Style.itemRemove}
            onClick={() => this.props.removeFromCart(index)}
          >
            Xoá
          </div>
        </div>
      );
    });
  }
  handleLoadingPayment = () => {
    const { loading } = this.props.orderCreate;
    const { PayLoading } = this.props.orderPay;
    if (loading || PayLoading) {
      Swal.fire({
        title: "Vui lòng đợi trong giây lát !",
        html: "Đang chuyển hướng trang...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    } else {
      Swal.close();
    }
  };
  render() {
    const { cart_items = [] } = this.props.cart;
    const { loading } = this.props.orderCreate;
    const {
      fullName = "",
      phoneNumber,
      address,
      note,
      paymentMethod,
      email,
    } = this.state.shipping_address;
    return (
      <div className={Style.CheckoutInformationContainer}>
        <div className={Style.left}>
          <h3>Thông tin giao hàng</h3>
          {this.handleLoadingPayment()}
          <form>
            <div className="mb-3">
              <label htmlFor="fullName" className="form-label">
                Họ và tên
              </label>
              <input
                type="text"
                value={fullName}
                onChange={this.handleChangeInput}
                className="form-control "
                id="fullName"
                placeholder="Tên người nhận hàng"
                required
              />
              {this.renderError("fullName", "Họ và tên không được để trống.")}
            </div>

            <div className="mb-3">
              <label htmlFor="phoneNumber" className="form-label">
                Số điện thoại
              </label>
              <input
                type="text"
                value={phoneNumber}
                onChange={this.handleChangeInput}
                className="form-control"
                id="phoneNumber"
                placeholder="Số điện thoại người nhận hàng"
                required
              />
              {this.renderError(
                "phoneNumber",
                "Số điện thoại không được để trống."
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="text"
                value={email}
                onChange={this.handleChangeInput}
                className="form-control"
                id="email"
                placeholder="Email người nhận hàng"
                required
              />
              {this.renderError("email", "Email không được để trống.")}
            </div>
            <div className="mb-3">
              <label htmlFor="address" className="form-label">
                Địa chỉ
              </label>
              <input
                type="text"
                value={address}
                onChange={this.handleChangeInput}
                className="form-control"
                id="address"
                placeholder="Địa chỉ người nhận hàng"
                required
              />
              {this.renderError("address", "Địa chỉ không được để trống.")}
            </div>
            <div className="mb-3">
              <label htmlFor="note" className="form-label">
                Ghi chú
              </label>
              <textarea
                className="form-control"
                id="note"
                value={note}
                onChange={this.handleChangeInput}
                rows={3}
                placeholder="Nhập ghi chú"
              />
            </div>
            <div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="paymentMethod"
                  value="cashOnDelivery"
                  checked={paymentMethod === "cashOnDelivery"}
                  onChange={this.handlePaymentMethodChange}
                  id="cashOnDelivery"
                />
                <label className="form-check-label" htmlFor="cashOnDelivery">
                  Thanh toán khi nhận hàng
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="paymentMethod"
                  value="banking"
                  checked={paymentMethod === "banking"}
                  onChange={this.handlePaymentMethodChange}
                  id="banking"
                />
                <label className="form-check-label" htmlFor="banking">
                  Thanh toán banking
                </label>
              </div>
            </div>
          </form>
          <OvButton
            name="Information Buyer Form"
            status={this.handleCheckoutStatus(loading)}
            onClick={this.handleSubmit}
            className={Style.CheckoutButton}
          >
            Đặt hàng
          </OvButton>
        </div>
        <div className={Style.right}>
          <h3>
            Giỏ hàng (<span>{Object.keys(cart_items).length}</span> sản phẩm)
          </h3>
          <hr />
          <div className={Style.ListProducts}>
            <div className="col-12">{this.renderOrderCartItems()}</div>
            <p>
              Tổng cộng: <span>{this.calculateTotal()}</span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    orderCreate: state.orderCreate,
    orderPay: state.orderPay,
  };
};

const mapDispatchToProps = {
  addToCart,
  decrementCartItem,
  removeFromCart,
  CreateOrderPaymentVNPay,
  CreateOrderForClient,
  SaveShippingAddress,
  handleGetCartItems
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CheckoutInformation));
