import React, { Component } from "react";
import Style from "./style.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import { Navigation, A11y, Autoplay } from "swiper";
import { DEFAULT_IMAGE_ITEM, isNewPost } from "../../../lib/Functions";
import moment from "moment";

export default class BlogModule extends Component {
  render() {
    const { allBlog = {} } = this.props;
    return (
      <div className=" my-5 container">
        <Link to="/blog" className={Style.topPosts}>
          <h2 className="mb-4">Bài viết mới nhất</h2>
        </Link>
        <div className={Style.BlogModuleInner}>
          {Object.keys(allBlog).length > 0 ? (
            <Swiper
              modules={[Navigation, A11y, Autoplay]}
              spaceBetween={50}
              slidesPerView={1}
              navigation
              pagination={{ clickable: true }}
              style={{ height: "350px" }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                460: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                },
              }}
            >
              {Object.entries(allBlog)
                .filter(([key, post]) => !post.isHiddenPost) // Filter out hidden posts
                .sort(([keyA, postA], [keyB, postB]) =>
                  moment(postB.time_stamps, "MMM Do YY").diff(
                    moment(postA.time_stamps, "MMM Do YY")
                  )
                ) // Sort by timestamp
                .slice(0, 8) // Take the newest 8 posts
                .map(([key, post]) => (
                  <SwiperSlide key={key}>
                    <div className={`${Style.card} card`}>
                      {isNewPost(post.time_stamps) && (
                        <span className={`${Style.badge} badge badge-primary`}>
                          Mới nhất
                        </span>
                      )}
                      <Link
                        to={`/blog/${post.slug}`}
                        className={Style.mainSlug}
                      >
                        <img
                          className={Style.card_thumbnail}
                          src={post.thumbnail_images || DEFAULT_IMAGE_ITEM}
                          alt={post.title_blog}
                        />
                      </Link>
                      <div className={`${Style.card_body} card-body`}>
                        <Link
                          to={`/blog/${post.slug}`}
                          className={Style.post_slug}
                        >
                          <h5 className="card-title">{post.title_blog}</h5>
                        </Link>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          ) : (
            <div className="alert alert-warning" role="alert">
              Không có bài viết nào được hiển thị !
            </div>
          )}
        </div>
      </div>
    );
  }
}
