import React from "react";
import { HiOutlineMenu } from "react-icons/hi";
import Style from "./style.module.scss";

const NavigationBar = (props) => {
  const { mergedLinks = {} } = props;
  return (
    <div className={Style.NavigationBar}>
      <span
        className={`${Style.BtnCanvas}`}
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasWithBackdrop"
        aria-controls="offcanvasWithBackdrop"
      >
        <HiOutlineMenu />
      </span>
      <div
        className={`${Style.Canvas_mobile} offcanvas offcanvas-start`}
        tabIndex={-1}
        id="offcanvasWithBackdrop"
        aria-labelledby="offcanvasWithBackdropLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title fw-bold" id="offcanvasWithBackdropLabel">
            Tất cả danh mục
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <div className="offcanvas-body">
          {mergedLinks.map((el, index) => {
            const { name, categoryKey, link } = el;
            return (
              <li key={index}>
                <a href={link ? link : `/categories/${categoryKey}`}>{name}</a>
              </li>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default NavigationBar;
