import React, { Component } from "react";
import Style from "./style.module.scss";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  AddToCartNotification,
  CalculatePrice,
  DEFAULT_IMAGE_ITEM,
  FormatCurrencyToVND,
  FormatUrl,
} from "../../lib/Functions";
import Img from "react-cool-img";
import { Users } from "../../lib/Services";
import OvButton from "../../fields/Button";
const { addToCart, decrementCartItem, removeFromCart } = Users.PostRequests;
const { GetAllItems, GetAllCategories } = Users.GetRequests;

class CategoriesDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeCategory: "all",
      activeCategoryName: "Tất cả",
    };
  }

  componentDidMount() {
    const { categoryKeyRedirect } = this.props;
    this.onGetAllProduct();
    if (categoryKeyRedirect) {
      this.setState({ activeCategory: categoryKeyRedirect });
    } else {
      return { ...this.state };
    }
  }

  onGetAllProduct = () => {
    this.props.GetAllItems();
    this.props.GetAllCategories();
  };

  handleCategoryClick = (categoryKey) => {
    const { allCategories } = this.props;
    const categoryName = allCategories[categoryKey]?.name || "Tất cả";
    this.setState({
      activeCategory: categoryKey,
      activeCategoryName: categoryName,
    });
  };
  handleAddToCart = (product, quantity) => {
    AddToCartNotification(product, quantity);
    this.props.addToCart(product, quantity);
  };
  renderProductList(category) {
    const { allItems = {} } = this.props;
    const filteredProducts = Object.values(allItems)?.filter((product) => {
      return category === "all" || product.category_key === category;
    });
    return (
      <div className={`${Style.row} row`}>
        {filteredProducts.map((product, index) => {
          if (!product.isPublishedProduct) {
            return null;
          }
          const isAvailableImage =
            Array(product.thumbnail_image).length > 0 &&
            product.thumbnail_image !== undefined
              ? product.thumbnail_image[0]
              : DEFAULT_IMAGE_ITEM;
              const outOfStock = product.itemIsInStock;
          return (
            <div
              key={index}
              className={`${Style.card_container} col-lg-3 col-md-4 col-sm-6 col-6 my-2`}
            >
              <div className={`${Style.card} card`}>
                <Img
                  lazy={true}
                  debounce={1000}
                  src={isAvailableImage}
                  className={`${Style.zoomEffect} card-img-top `}
                  alt="Product Food Street"
                />
                {product.itemIsOnSale === true && product.itemSaleRate > 0 && (
                  <div className={`${Style.card_discount} fw-bold`}>
                    -{product.itemSaleRate}%
                  </div>
                )}
                <div className={`${Style.card_body} card-body`}>
                  <Link
                    to={{
                      state: {
                        singleProductData: product,
                      },
                      pathname: `/product/${FormatUrl(product.item_name)}?_id=${
                        product._id
                      }`,
                    }}
                    className={`${Style.card_title} card-title fs-6 mb-5`}
                  >
                    {product.item_name}
                  </Link>
                  <p className="card-text text-start fs-6">
                    <span className={Style.card_price}>
                      {FormatCurrencyToVND(CalculatePrice(product))}
                    </span>

                    <span className={Style.card_price_origin}>
                      {product.itemIsOnSale === true &&
                        FormatCurrencyToVND(product.item_price)}
                    </span>
                  </p>
                  <OvButton
                    name="Add to cart"
                    status={outOfStock ? "active" : "inactive"}
                    className={Style.addToCart}
                    onClick={() => this.handleAddToCart(product, 1)}
                  >
                       {outOfStock ? "đặt món" : "hết hàng"}
                  </OvButton>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
  render() {
    const { activeCategory, activeCategoryName } = this.state;
    const { allCategories = {} } = this.props;
    const categories = Object.entries(allCategories);
    const allCategory = { all: "all", obj_2: { name: "Tất cả" } };
    const allCategoriesWithDefault = [
      Object.values(allCategory),
      ...categories,
    ];
    return (
      <div className={Style.CategoriesDetailContainer}>
        <div className={`${Style.CategoriesBanner} row`}>
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            <Link to={""}>
              <Img
                src={"/images/cate_slider_img_1.png" || DEFAULT_IMAGE_ITEM}
                alt="banner quảng cáo 1"
              />
            </Link>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            <Link to={""}>
              <Img
                src={"/images/cate_slider_img_2.png" || DEFAULT_IMAGE_ITEM}
                alt="banner quảng cáo 2"
              />
            </Link>
          </div>
        </div>
        <div className={Style.CategoriesSlider}>
          <h2>{activeCategoryName}</h2>
          <div className={`${Style.nav_pills_container}`}>
            <ul
              className={`${Style.nav_pills_slider} nav nav-pills mb-3`}
              id="pills-tab"
              role="tablist"
            >
              {allCategoriesWithDefault.map(([categoryKey, categoryValue]) => {
                if (categoryValue.status === "hidden") {
                  return null;
                }
                return (
                  <li
                    key={categoryKey}
                    className="nav-item"
                    role="presentation"
                  >
                    <button
                      className={`${Style.nav_link} ${
                        categoryKey === activeCategory
                          ? `${Style.nav_link_active}`
                          : ""
                      }`}
                      id={`pills-${categoryKey}-tab`}
                      data-bs-toggle="pill"
                      onClick={() => this.handleCategoryClick(categoryKey)}
                      type="button"
                      role="tab"
                      aria-controls={`pills-${
                        categoryKey || this.props.categoryKeyRedirect
                      }`}
                      aria-selected={
                        categoryKey === activeCategory ||
                        this.props.categoryKeyRedirect === activeCategory
                      }
                    >
                      {categoryValue.name}
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="tab-content" id="pills-tabContent">
            {allCategoriesWithDefault.map(([categoryKey]) => (
              <div
                key={categoryKey}
                className={`tab-pane fade ${
                  categoryKey === activeCategory ? "show active" : ""
                }`}
                id={`pills-${categoryKey}`}
                role="tabpanel"
                aria-labelledby={`pills-${categoryKey}-tab`}
              >
                {this.renderProductList(categoryKey)}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    allItems: state.AllItemReducer.allItems,
    allCategories: state.AllCategoryReducer.allCategories,
  };
};
const mapDispatchToProps = {
  GetAllItems,
  GetAllCategories,
  addToCart,
  decrementCartItem,
  removeFromCart,
};
export default connect(mapStateToProps, mapDispatchToProps)(CategoriesDetail);
