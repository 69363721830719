import React, { Component } from "react";
import ContactUsComponent from "../components/ContactUsComponent/ContactUsComponent";
import Style from "./view.module.scss";
import { _updatePageTitleAndDescription } from "../utils/systems";


export default class ContactUs extends Component {
  componentDidMount() {

    _updatePageTitleAndDescription({
      description: "Trang liên hệ.",
      title: "Liên hệ với chúng tôi",
    });
  }
  render() {
    return (
      <div className={`${Style.ContactUsContainer} container`}>
        <ContactUsComponent />
      </div>
    );
  }
}
