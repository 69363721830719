export const NAVBAR_LINK_ITEM = [
  {
    name: "Trang chủ",
    link: "/",
  },
  {
    name: "Blog",
    link: "/blog",
  },
  {
    name: "Liên hệ",
    link: "/contact-us",
  },
  {
    name: "Tra cứu đơn hàng",
    link: "/tracking-order",
  },
];

export const FOOTER_LINK_ITEM = [
  {
    colTitle: "Tổng đài hỗ trợ",
    items: [
      { name: "Hotline", link: "tel:0772563563", col_link: "0772563563" },
      { name: "Địa chỉ", link: "#", col_link: "Đang cập nhật" },
      {
        name: "Email",
        link: "mailto:fifi131720@gmail.com",
        col_link: "fifi131720@gmail.com",
      },
    ],
  },
  {
    colTitle: "Liên hệ",
    items: [
      { name: "Liên hệ", link: "/contact-us" },
      { name: "Ưu đãi thành viên", link: "/uu-dai-thanh-vien" },
      { name: "Tin tức", link: "/tin-tuc" },
      { name: "Tuyển dụng", link: "/tuyen-dung" },
    ],
  },
  {
    colTitle: "Liên hệ",
    items: [
      { name: "Liên hệ", link: "#" },
      { name: "Hướng dẫn mua hàng", link: "/huong-dan-mua-hang" },
      { name: "Chính sách giao hàng", link: "/chinh-sach-giao-hang" },
    ],
  },
];
