// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import { Link, withRouter } from "react-router-dom";
// Style
import Style from "./style.module.scss";

// Functions
import { _getShortParagraph, RawHTML, _readTime } from "./functions";

// Fields
import Button from "../../fields/Button";

import { ImNewTab } from "react-icons/im";
import { DEFAULT_IMAGE_ITEM, isNewPost } from "../../lib/Functions";
import moment from "moment";

// Service
class BlogList extends React.Component {
  renderPost = (post = {}) => {
    const { body_blog, time_stamps, thumbnail_images, title_blog } = post;
    const isAvailableImage =
      thumbnail_images && thumbnail_images !== undefined
        ? thumbnail_images
        : DEFAULT_IMAGE_ITEM;
    const isNew = isNewPost(time_stamps);
    return (
      <div>
        <RawHTML className={Style.pub_date}>
          {time_stamps}
        </RawHTML>
        {isNew && <span className={Style.new_badge}>Mới nhất</span>}
        <div className={Style.article_preview_info}>
          <div className={Style.article_preview_text}>
            <div>
              <h2 className={Style.article_title}>{title_blog}</h2>
              <RawHTML className={Style.article_description} tag="p">
                {_getShortParagraph(body_blog)}
              </RawHTML>
              <Button
                className={Style.view_full_article_button}
                name="view full article"
              >
                <span>View full article</span>
                <span className={Style.new_window_icon}>
                  <ImNewTab />
                </span>
              </Button>
            </div>
            <div className={Style.article_extra_info}>
              <div className={Style.read_time}>
                {_readTime(body_blog)} phút đọc
              </div>
            </div>
          </div>
          <img
            alt={title_blog}
            className={Style.featured_image}
            src={isAvailableImage}
          />
        </div>
      </div>
    );
  };

  renderPostsList = () => {
    const { allBlog = {} } = this.props;
    const { listBlogs = {}, loading } = allBlog;

    if (loading) {
      return (
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      );
    }
    // Filter out hidden posts and sort by timestamp
    const visiblePosts = Object.values(listBlogs)
      .filter((post) => !post.isHiddenPost)
      .sort((a, b) =>
        moment(b.time_stamps, "MMM Do YY").diff(
          moment(a.time_stamps, "MMM Do YY")
        )
      );

    return visiblePosts.map((post, index) => {
      const { slug } = post;

      return (
        <div className={Style.link_container} key={index}>
          <Link
            className={Style.article}
            to={{ pathname: `/blog/${slug}`, state: { post_info: post } }}
          >
            {this.renderPost(post)}
          </Link>
        </div>
      );
    });
  };

  render() {
    return <div>{this.renderPostsList()}</div>;
  }
}

export default withRouter(BlogList);
