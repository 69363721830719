import React, { Component } from "react";
import Style from "./style.module.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Zoom from "react-medium-image-zoom";
import OvButton from "../../../fields/Button";
import { Users } from "../../../lib/Services";
import { Swiper, SwiperSlide } from "swiper/react";
import Navigation from "swiper/modules/navigation/navigation";
import {
  AddToCartNotification,
  CalculatePriceDisplay,
  DEFAULT_IMAGE_ITEM,
  FormatCurrencyToVND,
} from "../../../lib/Functions";
import { _updatePageTitleAndDescription } from "../../../utils/systems";
const { GetSingleItem, GetAllModifiers } = Users.GetRequests;
const { addToCart, updateModifiers, GetModifyGroup } = Users.PostRequests;
class ProductDetails extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.state = {
      selectedModifiers: [] || undefined,
      quantity: 1,
      item_description: "",
      thumbnail_image: [],
      item_name: "",
      item_price: 0,
      itemIsOnSale: false,
      itemIsInStock: false,
      modifier_group_keys: [],
      _id: "",
      itemSaleRate: 0,
      itemID: "",
      active_image: 0,
    };
  }

  componentDidMount() {
    this.props.GetAllModifiers();
    const { location, productData = {} } = this.props;
    const queryParams = new URLSearchParams(location.search);
    const _id = queryParams.get("_id");
    if (_id) {
      this.props.GetSingleItem(_id);
    }
    if (productData) {
      this.setState({
        _id: productData._id || "",
        item_description: productData.item_description || "",
        thumbnail_image: productData.thumbnail_image || [],
        item_name: productData.item_name || "",
        item_price: productData.item_price || 0,
        itemIsOnSale: productData.itemIsOnSale,
        itemSaleRate: productData.itemSaleRate || 0,
        itemIsInStock: productData.itemIsInStock || false,
        modifier_group_keys: productData.modifier_group_keys || [],
      });
      if (productData.modifier_group_keys) {
        this.props.GetModifyGroup(productData.modifier_group_keys);
      }
      _updatePageTitleAndDescription({
        description: "Trang sản phẩm chính.",
        title: productData.item_name || this.state.item_name,
      });
    } else {
      return null;
    }
  }
  componentDidUpdate(prevProps) {
    const { singleItem } = this.props;
    // Check if the singleItem prop has changed
    if (singleItem !== prevProps.singleItem) {
      // Check if singleItem is not null or undefined
      if (singleItem && Object.keys(singleItem).length > 0) {
        const {
          item_description,
          thumbnail_image,
          item_name,
          item_price,
          itemIsOnSale,
          _id,
          itemSaleRate,
          modifier_group_keys,
          itemIsInStock,
        } = singleItem;
        this.setState({
          item_description,
          thumbnail_image,
          item_name,
          item_price,
          itemIsOnSale,
          modifier_group_keys,
          itemIsInStock,
          _id,
          itemSaleRate,
        });
        _updatePageTitleAndDescription({
          description: "Trang sản phẩm chính.",
          title: item_name || "Chi tiết sản phẩm",
        });
        if (modifier_group_keys) {
          this.props.GetModifyGroup(modifier_group_keys);
        }
      } else {
        this.setState({ ...this.state });
      }
    }
  }

  handleQuantityChange = (newQuantity) => {
    if (newQuantity >= 1) {
      this.setState({ quantity: newQuantity });
    }
  };

  handleBtnMinusClick = () => {
    this.handleQuantityChange(this.state.quantity - 1);
  };

  handleBtnPlusClick = () => {
    this.handleQuantityChange(this.state.quantity + 1);
  };

  handleBtnAddToCart = (product, quantity, selectedModifiers) => {
    const { groupModifiers = [] } = this.props;
    if (Array.isArray(groupModifiers)) {
      const isModifiersValid = groupModifiers.every((groupModifier) => {
        if (groupModifier.modify_group_is_required) {
          return selectedModifiers.some(
            (modifier) =>
              modifier.modifier_group_key === groupModifier.modifier_group_key
          );
        }
        return true;
      });
      if (!isModifiersValid) {
        alert(
          "Vui lòng chọn ít nhất 1 món ăn kèm của Đồ ăn kèm bánh tráng trộn."
        );
        return;
      }
      this.props.addToCart(product, quantity, selectedModifiers);
    } else {
      this.props.addToCart(product, quantity, selectedModifiers);
    }
    AddToCartNotification(product, quantity);
  };

  handleImageClick = (index) => {
    this.setState({ active_image: index });
  };

  renderAlbumImages = () => {
    const { thumbnail_image = [] } = this.state;
    const is_album_available = thumbnail_image.length > 0;
    if (is_album_available) {
      return (
        <div className={Style.thumbnailImage}>
          <Swiper
            modules={[Navigation]}
            navigation
            slidesPerView={1}
            spaceBetween={10}
            breakpoints={{
              576: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 3,
              },
              992: {
                slidesPerView: 3,
              },
              1200: {
                slidesPerView: 3,
              },
            }}
          >
            {thumbnail_image.map((item, index) => (
              <SwiperSlide key={index} className={Style.SwiperSlide}>
                <div className={`${Style.album_slide} swiper-slide-content`}>
                  <img
                    className={`${
                      this.state.active_image === index ? Style.active : ""
                    }`}
                    src={item}
                    alt={`Thumbnail ${index}`}
                    onClick={() => this.handleImageClick(index)}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      );
    }
  };

  handleModifierSelection = (modifierId, modifier) => {
    const { selectedModifiers } = this.state;
    const { groupModifiers } = this.props;

    const modifierGroupKey = modifier.modifier_group_key;

    // Find the index of the modifier group in groupModifiers
    const groupIndex = groupModifiers.findIndex(
      (group) => group.modifier_group_key === modifierGroupKey
    );

    // Check if allow_multiple is false for the modifier group
    if (
      groupIndex !== -1 &&
      !groupModifiers[groupIndex].modify_group_allow_multiple
    ) {
      // Deselect all previously selected modifiers from the same group
      const newSelectedModifiers = selectedModifiers.filter(
        (item) => item.modifier_group_key !== modifierGroupKey
      );
      // Add the newly selected modifier
      newSelectedModifiers.push(modifier);

      // Assuming updateModifiers accepts only the new selected modifiers
      this.props.updateModifiers(newSelectedModifiers);
      this.setState({ selectedModifiers: newSelectedModifiers });
    } else {
      // If allow_multiple is true or groupModifiers is not found, proceed as before
      const modifierIndex = selectedModifiers.findIndex(
        (item) => item.modifier_key === modifierId
      );
      let newSelectedModifiers = [];

      if (modifierIndex !== -1) {
        // If modifier is already selected, remove it
        newSelectedModifiers = [
          ...selectedModifiers.slice(0, modifierIndex),
          ...selectedModifiers.slice(modifierIndex + 1),
        ];
      } else {
        newSelectedModifiers = [...selectedModifiers, modifier];
      }

      // Assuming updateModifiers accepts both index and new selected modifiers
      this.props.updateModifiers(modifierIndex, newSelectedModifiers);
      this.setState({ selectedModifiers: newSelectedModifiers });
    }
  };
  handleRenderModifier = () => {
    const { selectedModifiers } = this.state;
    const { groupModifiers = [], groupModifiersLoading = false } = this.props;

    if (!Array.isArray(groupModifiers)) {
      return <div></div>;
    }

    if (groupModifiersLoading) {
      return <div>Loading...</div>;
    } else {
      return groupModifiers.map((groupModifier) => (
        <div key={groupModifier.modifier_group_key} className="form-check mb-2">
          <div className={Style.groupModifiersTitle}>
            <p className={Style.modifier_group_name}>
              {groupModifier.modifier_group_name}
            </p>
            <p className={`${Style.modify_group_is_required} text-danger`}>
              {groupModifier.modify_group_is_required &&
                `(Vui lòng chọn ít nhất 1 món ăn kèm của ${groupModifier.modifier_group_name})`}
            </p>
          </div>
          {groupModifier.modifiers.map((modifier) => (
            <div key={modifier.modifier_key} className="mb-2">
              <input
                className="form-check-input"
                type={
                  groupModifier.modify_group_allow_multiple
                    ? "checkbox"
                    : "radio"
                }
                id={modifier.modifier_key}
                checked={selectedModifiers.some(
                  (item) => item.modifier_key === modifier.modifier_key
                )}
                onChange={() =>
                  this.handleModifierSelection(modifier.modifier_key, modifier)
                }
              />
              <label
                className="form-check-label"
                htmlFor={modifier.modifier_key}
              >
                {modifier.modifier_name} - {modifier.modifier_price} đ
              </label>
            </div>
          ))}
        </div>
      ));
    }
  };

  render() {
    const {
      item_description,
      thumbnail_image,
      item_name,
      item_price,
      _id,
      itemSaleRate,
      selectedModifiers,
      itemIsOnSale,
      itemIsInStock,
    } = this.state;
    const SavedPriceDiscount = (item_price * itemSaleRate) / 100;
    const isAvailableImage =
      Array(thumbnail_image).length > 0 && thumbnail_image !== undefined
        ? thumbnail_image
        : DEFAULT_IMAGE_ITEM;
    return (
      <div className={`${Style.DetailContainer} row`}>
        <div className={`col-lg-6 col-md-6 col-sm-12 col-12`}>
          <div className={Style.thumbnailImageMain}>
            <Zoom>
              <img
                src={isAvailableImage[this.state.active_image]}
                alt={item_name}
              />
            </Zoom>
          </div>
          <div className={Style.thumbnailImageAlbum}>
            <p>Click chuột lên hình để phóng to</p>
            {this.renderAlbumImages()}
          </div>
        </div>
        <div
          className={`${Style.productDescription} col-lg-6 col-md-6 col-sm-12 col-12`}
        >
          <h4>{item_name}</h4>
          <p>{item_description}</p>
          <p>
            Mã sản phẩm: <span>{_id}</span>
          </p>

          <div className={Style.productPriceBox}>
            <h3>
              {FormatCurrencyToVND(CalculatePriceDisplay({ ...this.state }))}
            </h3>
            {itemIsOnSale && itemSaleRate > 0 && (
              <div className={Style.productDiscountPrice}>
                <p className={Style.GiaThiTruong}>
                  Giá thị trường: <span>{FormatCurrencyToVND(item_price)}</span>
                </p>
                <p className={Style.GiaTietKiem}>
                  Tiết kiệm: {FormatCurrencyToVND(SavedPriceDiscount)}
                </p>
              </div>
            )}

            <div className={Style.optionModifier}>
              {this.handleRenderModifier()}
            </div>
            <div className={Style.item_quantity}>
              <p>Số lượng:</p>
              <button
                className={Style.btn_minus}
                onClick={() => this.handleBtnMinusClick()}
              >
                -
              </button>
              <input
                readOnly
                className={Style.custom_item_qty}
                value={this.state.quantity}
                onChange={this.handleQuantityChange()}
              />
              <button
                className={Style.btn_plus}
                onClick={() => this.handleBtnPlusClick()}
              >
                +
              </button>
            </div>
            <div className={Style.addToOrder}>
              <OvButton
                name="đặt món"
                status={itemIsInStock ? "active" : "inactive"}
                onClick={() =>
                  this.handleBtnAddToCart(
                    { ...this.state },
                    this.state.quantity,
                    selectedModifiers
                  )
                }
              >
                {itemIsInStock ? "đặt món" : "hết hàng"}
              </OvButton>
            </div>
          </div>
          {/* <div className={Style.productInformation}>
            <h4>THÔNG TIN CHI TIẾT</h4>
          </div> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    singleItem: state.GetSingleItemReducer.singleItem,
    allModifiers: state.AllModifierReducer.allModifiers,
    groupModifiers: state.GroupModifierReducer.groupModifiers,
    groupModifiersLoading: state.GroupModifierReducer.loading,
  };
};

const mapDispatchToProps = {
  addToCart,
  updateModifiers,
  GetSingleItem,
  GetModifyGroup,
  GetAllModifiers,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProductDetails));
