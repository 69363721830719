const CART_ADD_ITEM = "CART_ADD_ITEM";
const CART_INCREMENT_ITEM = "CART_INCREMENT_ITEM";
const CART_DECREMENT_ITEM = "CART_DECREMENT_ITEM";
const UPDATE_CART_ITEMS = "UPDATE_CART_ITEMS"
const CART_REMOVE_ITEM = "CART_REMOVE_ITEM";
const CART_CLEAR_ITEMS = "CART_CLEAR_ITEMS";
const CART_SAVE_PAYMENT_METHOD = "CART_SAVE_PAYMENT_METHOD";
const CART_SAVE_SHIPPING_ADDRESS = "CART_SAVE_SHIPPING_ADDRESS";
const CART_UPDATE_MODIFIERS = "CART_UPDATE_MODIFIERS";
export {
  CART_ADD_ITEM,
  CART_DECREMENT_ITEM,
  CART_CLEAR_ITEMS,
  CART_REMOVE_ITEM,
  CART_SAVE_PAYMENT_METHOD,
  CART_SAVE_SHIPPING_ADDRESS,
  CART_UPDATE_MODIFIERS,
  CART_INCREMENT_ITEM,
  UPDATE_CART_ITEMS
};
