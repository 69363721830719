import React from "react";
import {
  HeroModule,
  PreviewProductModule,
  TopProductModule,
  BlogModule,
} from "../components/HomeModules";

import { connect } from "react-redux";
import { _updatePageTitleAndDescription } from "../utils/systems";
import GetRequests from "../lib/Services/Users/GetRequests.js";
const { GetAllItems, GetAllCategories, GetAllModifiers, GetAllBlog } =
  GetRequests;
class HomeScreen extends React.Component {
  componentDidMount = () => {
    this.handleGetProduct();

    _updatePageTitleAndDescription({
      description: "Trang chủ của ăn vặt.",
      title: "Chuyên Sản Xuất & Cung Cấp Sản Phẩm Ăn Vặt Cao Cấp",
    });
  };

  handleGetProduct() {
    this.props.GetAllItems();
    this.props.GetAllCategories();
    this.props.GetAllModifiers();
    this.props.GetAllBlog();
  }
  render() {
    return (
      <React.Fragment>
        <HeroModule />
        <PreviewProductModule
          allItems={this.props.allItems}
          allCategories={this.props.allCategories}
          allModifiers={this.props.allModifiers}
        />
        <TopProductModule
          allItems={this.props.allItems}
          allCategories={this.props.allCategories}
          allModifiers={this.props.allModifiers}
        />
        <BlogModule allBlog={this.props.allBlog} />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    allItems: state.AllItemReducer.allItems,
    allCategories: state.AllCategoryReducer.allCategories,
    allModifiers: state.AllModifierReducer.allModifiers,
    allBlog: state.allBlog.listBlogs,
  };
};

const mapDispatchToProps = {
  GetAllItems,
  GetAllCategories,
  GetAllModifiers,
  GetAllBlog,
};
export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);
