import React, { Component } from "react";
import Navbar from "../Navbar/Navbar";
export default class Header extends Component {
  render() {
    return (
        <div className={`container`}>
            <Navbar />
        </div>
    )
  }
}
