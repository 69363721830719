import React, { Component } from "react";
import Style from "./style.module.scss";
import Img from "react-cool-img";
import OvButton from "../../../fields/Button";
import Zoom from "react-medium-image-zoom";

export default class HeroModule extends Component {
  render() {
    return (
      <div className={`${Style.HeroContainer}`}>
        <div className={`${Style.HeroInner} container`}>
          <div className={Style.left}>
            <h5>Ăn Vặt FiFi</h5>
            <h2>Về Chúng Tôi</h2>
            <p className={Style.HeroLeftParagraph}>
              Chuyên Sản Xuất & Cung Cấp Sản Phẩm Ăn Vặt Cao Cấp. Hiện đã có chi
              nhánh 6 Tỉnh ( Hồ Chí Minh - Đồng Nai - Đà Lạt - Nha Trang - Đà
              Nẵng - Quảng Trị ).
            </p>
            <div className={Style.readMoreBox}>
              <OvButton name="read more">Xem thêm</OvButton>
            </div>
          </div>
          <div className={Style.right}>
            <Zoom>
              <Img
                placeholder={""}
                lazy={true}
                debounce={1000}
                src="/images/hero_image.png"
                alt="Food Street"
              />
            </Zoom>
          </div>
        </div>
      </div>
    );
  }
}
