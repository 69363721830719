import React, { Component } from "react";
import Style from "./style.module.scss";
import { FOOTER_LINK_ITEM } from "../Layout/_constants";
import OvButton from "../../fields/Button/index";
import { Users } from "../../lib/Services";
import Swal from "sweetalert2";
const { sendEmailToOwner } = Users.PostRequests;
class ContactUsComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isTabletOrMobile: window.innerWidth <= 994,
      fullName: "",
      phoneNumber: "",
      description: "",
      email_client: "",
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  handleResize = () => {
    this.setState({
      isTabletOrMobile: window.innerWidth <= 994,
    });
  };
  handleChangeInput = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmitForm = async() => {
    const {
      fullName = "",
      phoneNumber = "",
      description = "",
      email_client = "",
    } = this.state;
    try {
      const contactInfo = {
        fullName,
        phoneNumber,
        description,
        email_client,
      };
      Swal.fire({
        title: "Đang xử lý...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      await sendEmailToOwner(contactInfo);
      Swal.fire({
        icon: "success",
        title: "Thành công!",
        text: "Cảm ơn bạn đã góp ý với chúng tôi. Chúng tôi yêu bạn !",
      });
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Lỗi!",
        text: "Không gửi được. Vui lòng thử lại sau.",
      });
    }
    this.setState({
      fullName: "",
      phoneNumber: "",
      description: "",
      email_client: "",
    });
  };

  render() {
    const {
      isTabletOrMobile,
      fullName = "",
      phoneNumber = "",
      description = "",
      email_client = "",
    } = this.state;
    const CONTACT_INFO = FOOTER_LINK_ITEM[0];
    const { items = [] } = CONTACT_INFO;
    return (
      <React.Fragment>
        <div className={`row my-4 ${isTabletOrMobile ? "" : " container"}`}>
          <h1 className="mb-5">Liên hệ</h1>
          <div className="col-12 col-sm-12 col-md-12 col-lg-4">
            {items.map((item, index) => (
              <li className={Style.contact_list} key={index}>
                <p>{item.name}:</p>
                <a href={item.link}>{item.col_link}</a>
              </li>
            ))}
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-8">
            <div className={Style.contact_box}>
              <h5>LIÊN HỆ VỚI CHÚNG TÔI</h5>
              <form>
                <div className="row">
                  <div className="col-sm-4 col-xs-12 my-2">
                    <label htmlFor="fullName" className="form-label">
                      Họ và tên
                    </label>
                    <input
                      type="text"
                      value={fullName}
                      onChange={this.handleChangeInput}
                      name="fullName"
                      className="form-control "
                      id="fullName"
                      placeholder="Nhập họ và tên"
                      required
                    />
                    {/* {this.renderError(
                      "fullName",
                      "Họ và tên không được để trống."
                    )} */}
                  </div>
                  <div className="col-sm-4 col-xs-12 my-2">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="text"
                      value={email_client}
                      onChange={this.handleChangeInput}
                      name="email_client"
                      className="form-control "
                      id="email"
                      placeholder=" Nhập địa chỉ email"
                      required
                    />
                    {/* {this.renderError(
                      "fullName",
                      "Họ và tên không được để trống."
                    )} */}
                  </div>
                  <div className="col-sm-4 col-xs-12 my-2">
                    <label htmlFor="phoneNumber" className="form-label">
                      Số điện thoại
                    </label>
                    <input
                      type="text"
                      value={phoneNumber}
                      onChange={this.handleChangeInput}
                      name="phoneNumber"
                      className="form-control "
                      id="phoneNumber"
                      placeholder="Nhập số điện thoại"
                      required
                    />
                    {/* {this.renderError(
                      "fullName",
                      "Họ và tên không được để trống."
                    )} */}
                  </div>
                  <div className="col-sm-12 col-xs-12 my-2">
                    <label htmlFor="note" className="form-label">
                      Nội dung
                    </label>
                    <textarea
                      className="form-control"
                      id="note"
                      value={description}
                      name="description"
                      onChange={this.handleChangeInput}
                      rows={4}
                      placeholder="Nhập nội dung"
                    />
                  </div>
                </div>
              </form>
              <OvButton
                type="submit"
                name="Submit message"
                onClick={this.handleSubmitForm}
                className={`${Style.btn_message} my-3`}
              >
                Gửi tin nhắn
              </OvButton>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ContactUsComponent;
