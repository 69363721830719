import {
  GET_ALL_CATEGORY,
  GET_ALL_ITEM,
  GET_ALL_MODIFIERS,
  GET_MODIFY_GROUP_FAILED,
  GET_MODIFY_GROUP_REQUEST,
  GET_MODIFY_GROUP_SUCCESS,
  GET_SINGLE_ITEM_FAILED,
  GET_SINGLE_ITEM_SUCCESS,
  GET_SINGLE_REQUEST,
} from "../lib/Constants/Product";

const AllItemReducer = (state = { allItems: {} }, action) => {
  switch (action.type) {
    case GET_ALL_ITEM:
      return { ...state, allItems: action.payload };
    default:
      return state;
  }
};

const AllCategoryReducer = (state = { allCategories: {} }, action) => {
  switch (action.type) {
    case GET_ALL_CATEGORY:
      return { ...state, allCategories: action.payload };
    default:
      return state;
  }
};

const AllModifierReducer = (state = { allModifiers: {} }, action) => {
  switch (action.type) {
    case GET_ALL_MODIFIERS:
      return { ...state, allModifiers: action.payload };
    default:
      return state;
  }
};

const GroupModifierReducer = (state = { groupModifiers: {} }, action) => {
  switch (action.type) {
    case GET_MODIFY_GROUP_REQUEST:
      return {
        loading: true,
      };
    case GET_MODIFY_GROUP_SUCCESS:
      return {
        loading: false,
        success: true,
        groupModifiers: action.payload,
      };
    case GET_MODIFY_GROUP_FAILED:
      return {
        loading: false,
        success: false,
      };
    default:
      return state;
  }
};

const GetSingleItemReducer = (
  state = { singleItem: {}, loading: false },
  action
) => {
  switch (action.type) {
    case GET_SINGLE_REQUEST:
      return {
        loading: true,
      };
    case GET_SINGLE_ITEM_SUCCESS:
      return {
        loading: false,
        singleItem: action.payload,
      };
    case GET_SINGLE_ITEM_FAILED:
      return {
        loading: false,
      };
    default:
      return {};
  }
};
export {
  AllItemReducer,
  AllCategoryReducer,
  AllModifierReducer,
  GetSingleItemReducer,
  GroupModifierReducer,
};
