import React, { Component } from "react";
import { Link } from "react-router-dom";
import Style from "./style.module.scss";
import { IoIosArrowForward } from "react-icons/io";
import Img from "react-cool-img";
import {
  CalculatePrice,
  DEFAULT_IMAGE_ITEM,
  FormatCurrencyToVND,
  FormatUrl,
} from "../../../lib/Functions";
export default class TopProductModule extends Component {
  renderTopProduct = () => {
    const { allItems = {} } = this.props;
    const MAX_VISIBLE_PRODUCTS = 6;
    const visibleProducts = Object.values(allItems).slice(
      0,
      MAX_VISIBLE_PRODUCTS
    );
    return visibleProducts.map((product, index) => {
      if (!product.isPublishedProduct) {
        return null;
      }
      const isAvailableImage =
        Array(product.thumbnail_image).length > 0 &&
        product.thumbnail_image !== undefined
          ? product.thumbnail_image[0]
          : DEFAULT_IMAGE_ITEM;
      return (
        <div key={index} className="col-lg-6 col-sm-6 col-6 px-2">
          <div className={Style.productRowBox}>
            <Link to="/" className={Style.itemImgLink}>
              <Img src={isAvailableImage} alt={product.item_name} />
            </Link>
            <div className={Style.ProductContent}>
              <Link
                className={Style.itemNameLink}
                to={{
                  state: { singleProductData: product },
                  pathname: `product/${FormatUrl(product.item_name)}?_id=${
                    product._id
                  }`,
                }}
              >
                {product.item_name}
              </Link>
              <div className={Style.blockPrices}>
                <p className="card-text text-start fs-6">
                  <span className={Style.card_price}>
                    {FormatCurrencyToVND(CalculatePrice(product))}
                  </span>

                  <span className={Style.card_price_origin}>
                    {product.itemIsOnSale === true &&
                      product.itemSaleRate > 0 &&
                      FormatCurrencyToVND(product.item_price)}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };
  render() {
    return (
      <div className={`${Style.TopProductModule} container`}>
        <div className="row m-0">
          <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
            <div className="d-flex justify-content-between align-items-center mb-3 mx-3">
              <Link to="/top-product" className={Style.topProduct}>
                <h2>Món ăn nổi bật</h2>
              </Link>
              <Link to="/xemThem" className={Style.xemThem}>
                <p>Xem thêm</p>
                <IoIosArrowForward />
              </Link>
            </div>
            <div className={`${Style.listProducts}`}>
              {this.renderTopProduct()}
            </div>
          </div>
          <div
            className={`col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12`}
          ></div>
        </div>
      </div>
    );
  }
}
