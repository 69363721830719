import React from "react";
import { withRouter, Link } from "react-router-dom";
import Style from "./style.module.scss";


class WithBreadcrumbs extends React.Component {
  shouldIgnoreSegment(segment) {
    // Check if the segment includes '%' or '?'
    return segment.includes("%") || segment.includes("?");
  }

  generateCrumbs() {
    const pathArray = window.location.pathname.split("/").filter(Boolean);
    let currentPath = "";
    const crumbs = [];
    crumbs.push(
      <li key="home" className={`breadcrumb-item ${pathArray.length === 0 ? `active` : ""}`}>
        {pathArray.length === 0 ? "Home" : <Link to="/" className={Style.linkPath}>Home</Link>}
      </li>
    );

    pathArray.forEach((segment, index) => {
      if (this.shouldIgnoreSegment(segment)) {
        return; // Ignore segments with '%' or '?'
      }

      currentPath += `/${segment}`;
      const isLast = index === pathArray.length - 1;

      crumbs.push(
        <li key={index + 1} className={`breadcrumb-item ${isLast ? `${Style.active}` : ""}`}>
          {isLast ? segment : <Link to={currentPath} className={Style.linkPath}>{segment}</Link>}
        </li>
      );
    });

    return crumbs;
  }


  render() {
    const pathArray = window.location.pathname.split("/").filter(Boolean);
    if (pathArray.length === 0) {
      return null;
    }

    return (
      <nav
        aria-label="breadcrumb"
        className={`${Style.StyleBreadcrumbPadding} container`}
      >
        <ol className={`breadcrumb ${Style.linkPath}`}>{this.generateCrumbs()}</ol>
      </nav>
    );
  }
}

export default withRouter(WithBreadcrumbs);
