import { FormatCurrencyToVND } from "../lib/Functions";

export const STATUS_CODE = {
  SUCCESS: 200,
  NOT_FOUND: 404,
  SERVER_ERROR: 500,
};

export const _updatePageTitleAndDescription = ({
  description = "",
  title = "",
}) => {
  if (description.length > 0)
    document.getElementsByTagName("meta")["description"].content = description;
  if (title.length > 0) document.title = title;
};

export const emailHTMLTemplate = (
  order_id,
  fullName,
  phoneNumber,
  address,
  order_total
) => `
<!DOCTYPE html>
<html lang="vi">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Xác Nhận Đơn Hàng</title>
    <style>
        .copyable {
            cursor: pointer;
            color: blue;
            text-decoration: underline;
        }
    </style>
</head>
<body>
    <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto;">
        <h2>Xác Nhận Đơn Hàng</h2>
        <p>Kính gửi quý khách hàng,</p>
        <p>Chúng tôi rất vui mừng thông báo rằng đơn hàng của bạn đã được xử lý thành công.</p>
        <h3>Chi tiết đơn hàng của bạn: </h3>
        <p>Mã đơn hàng: <span class="copyable" onclick="copyToClipboard('${order_id}')">${order_id}</span></p>
        <ul>
          <li>Họ tên: <span>${fullName}</span></li>
          <li>SĐT: <span>${phoneNumber}</span></li>
          <li>Địa chỉ: <span>${address}</span></li>
        </ul>
        <p>Tổng tiền đơn hàng: ${FormatCurrencyToVND(order_total)}</p>
        <a href="https://www.taphoagenz.com/tracking-order">Nhấn vào đây để tra cứu đơn hàng !</a>
        <p>Nếu bạn có bất kỳ câu hỏi hoặc thắc mắc, vui lòng liên hệ với chúng tôi.</p>
        <p>Cảm ơn bạn đã lựa chọn dịch vụ của chúng tôi!</p>
        <p>Trân trọng,</p>
    </div>

    <script>
        // Function to copy text to clipboard
        function copyToClipboard(text) {
            const el = document.createElement('div');
            el.value = text;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            alert('Mã đơn hàng đã được sao chép!');
        }
    </script>
</body>
</html>
`;

export const contactUsHTMLTemplate = (
  fullName,
  phoneNumber,
  description,
  email_client
) => `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Contact Us</title>
    <style>
        body {
            font-family: Arial, sans-serif;
            background-color: #f8f9fa;
            margin: 0;
            padding: 0;
        }
        .contact-form {
            max-width: 600px;
            margin: 30px auto;
            background-color: #ffffff;
            padding: 20px;
            border-radius: 8px;
            box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
        }
        .contact-form h2 {
            margin-top: 0;
            color: #333333;
        }
        .contact-form label {
            font-weight: bold;
            color: #555555;
        }
        .contact-form div {
            margin-bottom: 15px;
        }
        .contact-form div#fullName,
        .contact-form div#phoneNumber,
        .contact-form div#description,
        .contact-form div#email_client {
            padding: 8px;
            border: 1px solid #cccccc;
            border-radius: 5px;
            background-color: #f0f0f0;
        }
    </style>
</head>
<body>
    <div class="contact-form">
        <h2>TIN NHẮN TỪ KHÁCH HÀNG TRANG TẠP HOÁ GENZ</h2>
        <div>
            <label for="fullName">Tên khách hàng:</label><br>
            <div id="fullName">${fullName}</div>
        </div>
        <div>
            <label for="phoneNumber">Số điện thoại:</label><br>
            <div id="phoneNumber">${phoneNumber}</div>
        </div>
        <div>
            <label for="description">Nội dung liên hệ:</label><br>
            <div id="description">${description}</div>
        </div>
        <div>
            <label for="email_client">Email:</label><br>
            <div id="email_client">${email_client}</div>
        </div>
    </div>
</body>
</html>
`;

const isLocal = () => {
  const { NODE_ENV } = process.env;
  return NODE_ENV === "development";
};
const DEV_FRONTEND_URL = "http://localhost:3000";
const PROD_FRONTEND_URL = process.env.REACT_APP_PROD_FRONT_END_URL;
export const BASE_FRONTEND_URL = isLocal()
  ? DEV_FRONTEND_URL
  : PROD_FRONTEND_URL;
