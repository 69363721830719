import { combineReducers } from "redux";
import {
  AllItemReducer,
  AllCategoryReducer,
  AllModifierReducer,
  GetSingleItemReducer,
  GroupModifierReducer
} from "../../Reducers/ProductReducer";
import { cartReducer } from "../../Reducers/CartReducer";
import {
  orderCreateReducer,
  orderDetailReducer,
  orderPayReducer,
  updateOrderReducer,
} from "../../Reducers/OrderReducer";
import { GetAllBlogReducer, GetSinglePost } from "../../Reducers/BlogReducer";
const rootReducer = combineReducers({
  // PRODUCT REDUCER
  AllItemReducer,
  AllCategoryReducer,
  AllModifierReducer,
  GetSingleItemReducer,
  GroupModifierReducer,
  // CART REDUCER
  cart: cartReducer,
  // ORDER REDUCER
  orderCreate: orderCreateReducer,
  orderPay: orderPayReducer,
  order: orderDetailReducer,
  // BLOG REDUCER
  allBlog: GetAllBlogReducer,
  singlePost: GetSinglePost,
  updateOrder: updateOrderReducer
});

export default rootReducer;
