import React, { Component } from "react";
import { Link } from "react-router-dom";
import Img from "react-cool-img";
import Style from "./style.module.scss";
import cx from "classnames";
import OvButton from "../../../fields/Button";
import {
  FormatCurrencyToVND,
  CalculatePrice,
  FormatUrl,
  AddToCartNotification,
  DEFAULT_IMAGE_ITEM,
} from "../../../lib/Functions";
import { connect } from "react-redux";
import { Users } from "../../../lib/Services";

const { addToCart, decrementCartItem, removeFromCart, handleGetCartItems } =
  Users.PostRequests;
const { GetSingleItem } = Users.GetRequests;

class PreviewProductModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTabletOrMobile: window.innerWidth <= 994,
      activeCategory: "all",
      selectedModifiers: [],
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.props.handleGetCartItems();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({
      isTabletOrMobile: window.innerWidth <= 994,
    });
  };
  handleCategoryClick = (categoryKey) => {
    this.setState({
      activeCategory: categoryKey,
    });
  };
  calculateSubtotal(product) {
    return CalculatePrice(product) * product.quantity;
  }
  calculateTotal = (formatPrice) => {
    const { cart_items = [] } = this.props.cart;
    const totalItemPrice = cart_items.reduce((total, product) => {
      const productPrice = CalculatePrice(product);
      return total + productPrice * product.quantity;
    }, 0);

    if (formatPrice === true) {
      return FormatCurrencyToVND(totalItemPrice);
    } else {
      return totalItemPrice;
    }
  };
  handleAddToCart = (product, quantity) => {
    AddToCartNotification(product, quantity);
    this.props.addToCart(product, quantity);
  };
  renderProductList(category) {
    const { allItems = {} } = this.props;
    const filteredProducts = Object.values(allItems)?.filter((product) => {
      return category === "all" || product.category_key === category;
    });
    const MAX_VISIBLE_PRODUCTS = 9;
    const visibleProducts = filteredProducts.slice(0, MAX_VISIBLE_PRODUCTS);
    return (
      <div className="row">
        {visibleProducts.length === 0 && (
          <div className="alert alert-warning" role="alert">
            Không có sản phẩm nào trong danh mục này.
          </div>
        )}
        {visibleProducts.map((product, index) => {
          if (!product.isPublishedProduct) {
            return null;
          }
          const isAvailableImage =
            Array(product.thumbnail_image).length > 0 &&
            product.thumbnail_image !== undefined
              ? product.thumbnail_image[0]
              : DEFAULT_IMAGE_ITEM;
          const outOfStock = product.itemIsInStock;
          return (
            <div key={index} className="col-lg-4 col-md-4 col-sm-6 col-6 my-2">
              <div className={`${Style.card} card`}>
                <Img
                  lazy={true}
                  debounce={1000}
                  src={isAvailableImage}
                  className={`${Style.zoomEffect} card-img-top `}
                  alt="Product Food Street"
                />
                {product.itemIsOnSale === true && product.itemSaleRate > 0 && (
                  <div className={`${Style.card_discount} fw-bold`}>
                    -{product.itemSaleRate}%
                  </div>
                )}
                <div className={`${Style.card_body} card-body`}>
                  <Link
                    to={{
                      state: {
                        singleProductData: product,
                      },
                      pathname: `product/${FormatUrl(product.item_name)}?_id=${
                        product._id
                      }`,
                    }}
                    className={`${Style.card_title} card-title fs-6`}
                  >
                    {product.item_name}
                  </Link>
                  <p className="card-text text-start fs-6">
                    <span className={Style.card_price}>
                      {FormatCurrencyToVND(CalculatePrice(product))}
                    </span>

                    <span className={Style.card_price_origin}>
                      {product.itemIsOnSale === true &&
                        product.itemSaleRate > 0 &&
                        FormatCurrencyToVND(product.item_price)}
                    </span>
                  </p>

                  <OvButton
                    status={outOfStock ? "active" : "inactive"}
                    name="Add to cart"
                    className={Style.addToCart}
                    onClick={() => this.handleAddToCart(product, 1)}
                  >
                    {outOfStock ? "đặt món" : "hết hàng"}
                  </OvButton>
                </div>
              </div>
            </div>
          );
        })}
        {filteredProducts.length > MAX_VISIBLE_PRODUCTS && (
          <div className="col-12 my-4">
            <Link to="/categories" className={Style.ViewMoreProduct}>
              Xem thêm
            </Link>
          </div>
        )}
      </div>
    );
  }
  renderOrderCartItems() {
    const { cart_items = [] } = this.props.cart;
    return cart_items.map((product, index) => {
      const { selectedModifiers = [] } = product;
      const isAvailableImage =
        Array(product.thumbnail_image).length > 0 &&
        product.thumbnail_image !== undefined
          ? product.thumbnail_image[0]
          : DEFAULT_IMAGE_ITEM;
      return (
        <div className={Style.itemRow} key={index}>
          <div className={Style.itemThumb}>
            <img src={isAvailableImage} alt={product.item_name} />
          </div>
          <div className={Style.itemName}>
            <Link
              className={Style.cartOrderItemName}
              to={{
                state: { singleProductData: product },
                pathname: `product/${FormatUrl(product.item_name)}?_id=${
                  product._id
                }`,
              }}
            >
              {product.item_name}
            </Link>
          </div>
          <div className={Style.itemModifiers}>
            {selectedModifiers.length > 0 && (
              <div className={Style.modifiers_item}>
                <p>Món thêm:</p>
                {selectedModifiers.map((modifier, index) => (
                  <div key={modifier.id}>
                    {index > 0 && ", "} {modifier.modifier_name}
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className={Style.itemPrice}>
            {FormatCurrencyToVND(CalculatePrice(product))}
          </div>
          <div
            className={Style.itemDecrement}
            onClick={() => this.props.decrementCartItem(index)}
          >
            -
          </div>
          <div className={Style.itemQuantity}>{product.quantity}</div>
          <div
            className={Style.itemIncrement}
            onClick={() =>
              this.props.addToCart(product, 1, selectedModifiers, index)
            }
          >
            +
          </div>
          <div
            className={Style.itemRemove}
            onClick={() => this.props.removeFromCart(index)}
          >
            Xoá
          </div>
        </div>
      );
    });
  }
  render() {
    const { allCategories = {}, cart = [] } = this.props;
    const { cart_items = [] } = cart;
    const countCartItem = Object.values(cart_items).reduce(
      (prevItem, currentItem) => {
        return prevItem + currentItem.quantity;
      },
      0
    );
    const { activeCategory, isTabletOrMobile } = this.state;
    const categories = Object.entries(allCategories);
    const allCategory = { all: "all", obj_2: { name: "Tất cả" } };

    const allCategoriesWithDefault = [
      Object.values(allCategory),
      ...categories,
    ];

    const mainPageCategories = [
      ["all", allCategory.obj_2], // Add "all" category
      ...Object.entries(allCategories),
    ];

    return (
      <div
        className={`${Style.PreviewProductContainer} text-center my-5 container`}
      >
        <h3 className="my-4">THỰC ĐƠN NGON - ĐẶT MÓN NGAY</h3>
        <ul
          className={cx(Style.navTabs, "nav nav-tabs")}
          id="myTab"
          role="tablist"
        >
          {mainPageCategories
            .filter(([categoryKey, categoryValue]) => {
              return (
                categoryValue.status !== "hidden" &&
                categoryValue.is_on_main_page !== false
              );
            })
            .slice(0, 5) 
            .map(([categoryKey, categoryValue]) => (
              <li className="nav-item" role="presentation" key={categoryKey}>
                <button
                  className={`${Style.nav_link} ${
                    categoryKey === activeCategory
                      ? `${Style.nav_link_active}`
                      : ""
                  }`}
                  onClick={() => this.handleCategoryClick(categoryKey)}
                  id={`${categoryKey}-tab`}
                  data-bs-toggle="tab"
                  data-bs-target={`#${categoryKey}`}
                  type="button"
                  role="tab"
                  aria-controls={categoryKey}
                  aria-selected={categoryKey === activeCategory}
                >
                  {categoryValue.name}
                </button>
              </li>
            ))}
        </ul>
        <div className="row m-0 ">
          <div
            className="tab-content col-lg-8 col-md-12 col-sm-12 col-12"
            id="myTabContent"
          >
            {allCategoriesWithDefault.map(([categoryKey]) => (
              <div
                className={`tab-pane fade ${
                  categoryKey === activeCategory ? "show active" : ""
                }`}
                id={categoryKey}
                role="tabpanel"
                aria-labelledby={`${categoryKey}-tab`}
                key={categoryKey}
              >
                {this.renderProductList(categoryKey)}
              </div>
            ))}
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 col-12">
            <div
              className={`${Style.stickyRight} position-sticky mobile-bottom-bar`}
            >
              <div
                className={`${Style.orderCart} accordion bottom-bar ${
                  isTabletOrMobile ? "fixed-bottom" : ""
                } text-white`}
                id={`${Style.cartSidebar} accordionExample`}
              >
                <div className="accordion-item">
                  <div
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                    id="headingOne"
                    className={` ${Style.cartMobile}`}
                  >
                    <span className={Style.countItemQuantity}>
                      {countCartItem} Món
                    </span>
                    <span className={Style.mobileTotalPrice}>
                      {FormatCurrencyToVND(this.calculateTotal())}
                    </span>
                    <span className={Style.closeBtn}>Đóng</span>
                  </div>
                  <div
                    id="collapseOne"
                    className={`accordion-collapse collapse ${
                      isTabletOrMobile ? "" : "show"
                    }`}
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className={`${Style.accordion_body} accordion-body`}>
                      <div className={Style.OrderCartItemHeader}>
                        <div className={Style.OrderCartItem}>
                          {this.renderOrderCartItems()}
                        </div>
                      </div>
                      <div className={Style.OrderCartItemBottom}>
                        <div className={Style.subTotal}>
                          <p>Tạm tính:</p>
                          <span>{this.calculateTotal(true)}</span>
                        </div>
                        <div className={Style.TotalBill}>
                          <p>Tổng cộng:</p>
                          <span>{this.calculateTotal(true)}</span>
                        </div>
                        <div className={Style.CheckoutBtnBox}>
                          <Link
                            to="/checkout"
                            className={Style.CheckoutButtonLink}
                          >
                            <OvButton
                              name="checkout button"
                              className={Style.CheckoutButton}
                            >
                              Thanh toán
                            </OvButton>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    singleItem: state.GetSingleItemReducer.singleItem,
  };
};
const mapDispatchToProps = {
  addToCart,
  decrementCartItem,
  removeFromCart,
  GetSingleItem,
  handleGetCartItems: handleGetCartItems,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreviewProductModule);
