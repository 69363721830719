import React from "react";
import "./App.css";
import "./responsive.css";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import HomeScreen from "./views/HomeScreen";
import HomeRouter from "./routes/HomeRouter";
import SingleProduct from "./views/SingleProduct";
import CheckoutScreen from "./views/CheckoutScreen";
import ContactUs from "./views/ContactUs";
import { CartInformation, SuccessCheckout } from "./components/CheckoutModules";
import { createBrowserHistory as history } from "history";
import NotFound from "./views/NotFound";
import Categories from "./views/Categories";
import TrackingOrder from "./views/TrackingOrder";
import Blog from "./views/Blog";
import Post from "./components/Post";

class App extends React.Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <HomeRouter path="/" component={HomeScreen} exact />
          <HomeRouter path="/categories" component={Categories} exact />
          <HomeRouter path="/categories/:id" component={Categories} exact />
          <HomeRouter path="/product/:title" component={SingleProduct} exact />
          <HomeRouter path="/checkout" component={CheckoutScreen} exact />
          <HomeRouter
            path="/checkout/status"
            component={SuccessCheckout}
            exact
          />
          <HomeRouter path="/cart" component={CartInformation} exact />
          <HomeRouter path="/contact-us" component={ContactUs} exact />
          <HomeRouter path="/tracking-order" component={TrackingOrder} exact />
          <HomeRouter path="/blog" component={Blog} exact />
          <HomeRouter path="/blog/:blogID" component={Post} exact />
          <HomeRouter component={NotFound} />
        </Switch>
      </Router>
    );
  }
}

export default App;
