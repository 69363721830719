import axios from "axios";

import {
  CART_ADD_ITEM,
  CART_CLEAR_ITEMS,
  CART_DECREMENT_ITEM,
  CART_REMOVE_ITEM,
  CART_SAVE_SHIPPING_ADDRESS,
  CART_UPDATE_MODIFIERS,
  UPDATE_CART_ITEMS,
} from "../../Constants/Cart";
import {
  GET_MODIFY_GROUP_FAILED,
  GET_MODIFY_GROUP_REQUEST,
  GET_MODIFY_GROUP_SUCCESS,
} from "../../Constants/Product";
import API_PATHS from "../API_Paths";
import {
  ORDER_CREATE_FAIL,
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_RESET,
  ORDER_CREATE_SUCCESS,
  ORDER_DETAILS_RESET,
  ORDER_PAY_FAIL,
  ORDER_PAY_REQUEST,
  ORDER_PAY_SUCCESS,
  UPDATE_ORDER_FAIL,
  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_SUCCESS,
} from "../../Constants/Order";
import {
  BASE_FRONTEND_URL,
  contactUsHTMLTemplate,
  emailHTMLTemplate,
} from "../../../utils/systems";

const addToCart =
  (product, quantity, selectedModifiers) => async (dispatch, getState) => {
    dispatch({
      type: CART_ADD_ITEM,
      payload: {
        _id: product._id,
        item_name: product.item_name || "",
        itemIsOnSale: product.itemIsOnSale,
        itemSaleRate: product.itemSaleRate,
        item_description: product.item_description,
        thumbnail_image: product.thumbnail_image,
        item_price: product.item_price,
        category_key: product.category_key,
        isPublishedProduct: product.isPublishedProduct,
        itemIsInStock: product.itemIsInStock,
        modifier_group_keys: product.modifier_group_keys,
        selectedModifiers: selectedModifiers || [],
        quantity,
      },
    });
    localStorage.setItem(
      "cart_items",
      JSON.stringify(getState().cart.cart_items)
    );
  };

const updateModifiers = (index, modifiers) => async (dispatch, getState) => {
  dispatch({
    type: CART_UPDATE_MODIFIERS,
    payload: { index, modifiers },
  });
  localStorage.setItem(
    "cart_items",
    JSON.stringify(getState().cart.cart_items)
  );
};

const decrementCartItem = (index) => async (dispatch, getState) => {
  dispatch({
    type: CART_DECREMENT_ITEM,
    payload: { index },
  });
  localStorage.setItem(
    "cart_items",
    JSON.stringify(getState().cart.cart_items)
  );
};

const removeFromCart = (index) => async (dispatch, getState) => {
  dispatch({
    type: CART_REMOVE_ITEM,
    payload: { index },
  });

  localStorage.setItem(
    "cart_items",
    JSON.stringify(getState().cart.cart_items)
  );
};

// ORDER ACTION HANDLER
const CreateOrderPaymentVNPay =
  (amount, returnUrl = `${BASE_FRONTEND_URL}/checkout`) =>
  async (dispatch, _) => {
    dispatch({ type: ORDER_PAY_REQUEST });
    try {
      const { data } = await axios.post(API_PATHS.CREATE_ORDER_PAYMENT_VNPAY, {
        amount,
        returnUrl,
      });
      dispatch({ type: ORDER_PAY_SUCCESS, payload: data });
    } catch (err) {
      dispatch({ type: ORDER_PAY_FAIL });
    }
  };

const CreateOrderForClient = (orderInfo) => async (dispatch, _) => {
  dispatch({ type: ORDER_CREATE_REQUEST });
  try {
    const { data } = await axios.post(API_PATHS.CREATE_ORDER_FOR_CLIENT, {
      orderInfo,
    });
    dispatch({ type: ORDER_CREATE_SUCCESS, payload: data });
    dispatch({ type: CART_CLEAR_ITEMS, payload: data });
    localStorage.clear();
    dispatch({ type: ORDER_CREATE_RESET });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: ORDER_CREATE_FAIL, payload: message });
  }
};

const SaveShippingAddress = (data) => async (dispatch) => {
  dispatch({
    type: CART_SAVE_SHIPPING_ADDRESS,
    payload: data,
  });

  localStorage.setItem("shipping_address", JSON.stringify(data));
};
const ResetOrderForClient = () => (dispatch, _) => {
  dispatch({ type: ORDER_CREATE_RESET });
};

const ResetOrderDetailForClient = () => (dispatch, _) => {
  dispatch({ type: ORDER_DETAILS_RESET });
};

const sendEmailToClient = (orderEmailInfo) => {
  const {
    order_id = "",
    email = "",
    fullName = "",
    phoneNumber = "",
    address = "",
    order_total = 0,
  } = orderEmailInfo;
  return new Promise(
    async (resolve, reject) =>
      await axios
        .post(API_PATHS.SEND_EMAIL_TO_CLIENT, {
          email: email,
          emailHTML: emailHTMLTemplate(
            order_id,
            fullName,
            phoneNumber,
            address,
            order_total
          ),
        })
        .then(({ data }) => resolve(data))
        .catch((err) => console.log(err))
  );
};

const sendEmailToOwner = (contactInfo) => {
  const {
    email = "didan.mobe@gmail.com",
    fullName = "",
    phoneNumber = "",
    description = "",
    email_client = "",
  } = contactInfo;
  return new Promise(
    async (resolve, reject) =>
      await axios
        .post(API_PATHS.SEND_EMAIL_TO_CLIENT, {
          email: email,
          emailHTML: contactUsHTMLTemplate(
            fullName,
            phoneNumber,
            description,
            email_client
          ),
        })
        .then(({ data }) => resolve(data))
        .catch((err) => console.log(err))
  );
};

const GetModifyGroup = (modifier_group_keys) => async (dispatch) => {
  dispatch({ type: GET_MODIFY_GROUP_REQUEST });
  try {
    const { data } = await axios.post(API_PATHS.GET_MODIFY_GROUP, {
      modifier_group_keys,
    });
    dispatch({ type: GET_MODIFY_GROUP_SUCCESS, payload: data });
  } catch (err) {
    dispatch({ type: GET_MODIFY_GROUP_FAILED });
    console.log("Error modifier group", err);
  }
};

const updateOrder = (orderId, orderInfo) => async (dispatch, _) => {
  dispatch({ type: UPDATE_ORDER_REQUEST });
  try {
    const { data } = await axios.post(API_PATHS.UPDATE_ORDER_BY_CLIENT, {
      orderId,
      orderInfo,
    });
    dispatch({ type: UPDATE_ORDER_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: UPDATE_ORDER_FAIL, payload: message });
  }
};
const UpdateItemInStock = (itemIds) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios.post(
        `${API_PATHS.GET_ITEM_BY_IDS}`,
        itemIds
      );
      resolve(data);
    } catch (err) {
      console.log("Error Cart Item Failed", err);
    }
  });
};

const handleGetCartItems = () => {
  return async (dispatch, getState) => {
    try {
      let cart_items = JSON.parse(localStorage.getItem("cart_items"));
      if (!Array.isArray(cart_items)) {
        cart_items = [];
      }
      const itemIds = cart_items.map((item) => ({
        _id: item._id,
        quantity: item.quantity,
        selectedModifiers: item.selectedModifiers,
      }));
      const data = await UpdateItemInStock({ itemIds });

      if (data) {
        // Update cart_items in local storage
        localStorage.setItem("cart_items", JSON.stringify(data));
        // Dispatch action to update cart_items in Redux store
        dispatch({
          type: UPDATE_CART_ITEMS,
          payload: data,
        });
      }
    } catch (error) {
      console.error("Error in handleGetCartItems:", error);
    }
  };
};
const PostRequests = {
  // MODIFIER GROUP
  GetModifyGroup,
  // CART ACTION
  addToCart,
  decrementCartItem,
  removeFromCart,
  updateModifiers,
  // ORDER ACTION
  CreateOrderPaymentVNPay,
  CreateOrderForClient,
  SaveShippingAddress,
  ResetOrderForClient,
  sendEmailToClient,
  ResetOrderDetailForClient,
  updateOrder,
  UpdateItemInStock,
  handleGetCartItems,
  sendEmailToOwner
};
export default PostRequests;
