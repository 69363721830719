import React, { Component } from "react";
import Style from "./style.module.scss";
import { Link } from "react-router-dom";
import { FOOTER_LINK_ITEM } from "../_constants";
import OvButton from "../../../fields/Button/index";
import { FaFacebookF, FaTwitter, FaYoutube, FaInstagram } from "react-icons/fa";

export default class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <div className={Style.Footer}>
          <div className="container">
            <div className="row">
              {FOOTER_LINK_ITEM.map((item, index) => (
                <div key={index} className={`${Style.FooterItem} col-sm-6 col-lg-3 col-12`}>
                  <h5>{item.colTitle}</h5>
                  {item.items.map((item, i) => (
                    <li key={i}>
                      <p>
                        {item.col_link ? (
                          <>
                            <span>{item.name}:</span>
                            <Link to={item.link}>{item.col_link}</Link>
                          </>
                        ) : (
                          <Link to={item.link}>{item.name}</Link>
                        )}
                      </p>
                    </li>
                  ))}
                </div>
              ))}
              <div
                className={`${Style.footer_col_4} col-sm-6 col-lg-3 col-12 text-white`}
              >
                <h5>Đăng ký nhận tin</h5>
                <p>
                  Nhận thông tin sản phẩm mới nhất, tin khuyến mãi và nhiều hơn
                  nữa.
                </p>
                <input placeholder="Email của bạn" />
                <OvButton className={Style.news_register} name="register">đăng ký</OvButton>
              </div>
            </div>
          </div>
        </div>
        <div className={Style.copyright}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12 col-sm-12 col-12 ">
                <div className={Style.section_1}>
                  <Link
                    rel="noreferrer"
                    to="https://www.facebook.com"
                    target="_blank"
                    title="Theo dõi trên Facebook mọi người nhé"
                  >
                    <FaFacebookF />
                  </Link>
                  <Link
                    rel="noreferrer"
                    to="https://www.twitter.com"
                    target="_blank"
                    title="Theo dõi trên Facebook mọi người nhé"
                  >
                    <FaTwitter />
                  </Link>
                  <Link
                    rel="noreferrer"
                    to="https://www.youtube.com"
                    target="_blank"
                    title="Theo dõi trên Facebook mọi người nhé"
                  >
                    <FaYoutube />
                  </Link>
                  <Link
                    rel="noreferrer"
                    to="https://www.instagram.com"
                    target="_blank"
                    title="Theo dõi trên Facebook mọi người nhé"
                  >
                    <FaInstagram />
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12 col-12">
                <div className={Style.section_2}>
                  <p>© Bản quyền thuộc về Ăn Vặt FiFi</p>
                  <span>|</span>
                  <p>Cung cấp bởi Cửa Hàng FiFi</p>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12 col-12">
                <div className={Style.section_3}>
                  <img
                    src="/images/payment_footer.png"
                    alt="thông tin thanh toán ăn vặt"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
