import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./rootReducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// SAVE CartItem
const cartItemsFromLocalStorage = localStorage.getItem("cart_items")
  ? JSON.parse(localStorage.getItem("cart_items"))
  : [];
const shippingAddressLocalStorage = localStorage.getItem("shipping_address")
  ? JSON.parse(localStorage.getItem("shipping_address"))
  : {};
const initialState = {
  cart: {
    cart_items: cartItemsFromLocalStorage,
    shipping_address: shippingAddressLocalStorage,
  },
};
const middleware = [thunk];

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
);

export default store;
