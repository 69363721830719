import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Spinner from "react-bootstrap/Spinner";
// Style
import Style from "./style.module.scss";

const OvButton = (props) => {
  const { status } = props;

  const onClick = () => {
    const { onClick } = props;
    if (status === "active" && onClick) props.onClick();
  };

  const renderStartIcon = () => {
    if (props.startIcon) {
      return <span className={Style.start_icon}>{props.startIcon}</span>;
    }
    return null;
  };

  const renderEndIcon = () => {
    if (props.endIcon) {
      return <span className={Style.end_icon}>{props.endIcon}</span>;
    }
    return null;
  };

  return (
    <button
      className={cx(
        Style.button,
        Style[props.variant],
        props.hasShadow && Style.hasShadow,
        props.className,
        (status === "inactive" || status === "loading") && Style.inactive
      )}
      disabled={props.disabled}
      formAction={props.formAction}
      name={props.name}
      onBlur={props.onBlur}
      size={props.size}
      onClick={() => status === "active" && onClick()}
      style={props.style}
      type={props.type}
      value={props.value}
    >
      {renderStartIcon()}
      {status === "loading" ? (
        <React.Fragment>
          <Spinner className={Style.Spinner} size={props.Spinner || "sm"} />
          <p className={Style.textLoading}>{props.loadingText} ...</p>
        </React.Fragment>
      ) : (
        <p>{props.children}</p>
      )}
      {renderEndIcon()}
    </button>
  );
};

OvButton.propTypes = {
  children: PropTypes.any,
  className: PropTypes.any,
  variant: PropTypes.oneOf(["primary", "secondary", "success"]),
  disabled: PropTypes.bool,
  hasShadow: PropTypes.bool,
  loadingText: PropTypes.string,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  status: PropTypes.oneOf(["active", "inactive", "loading"]),
  type: PropTypes.oneOf(["button", "reset", "submit"]),
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
};

OvButton.defaultProps = {
  variant: "primary",
  disabled: false,
  hasShadow: false,
  loadingText: "Loading",
  onClick: () => {},
  status: "active",
  type: "submit",
};

export default OvButton;
