import React, { Component } from "react";
import Style from "./style.module.scss";
import { Link } from "react-router-dom";
import {
  CalculatePrice,
  DEFAULT_IMAGE_ITEM,
  FormatCurrencyToVND,
  FormatUrl,
} from "../../../lib/Functions";
import { connect } from "react-redux";
import { Users } from "../../../lib/Services";
import OvButton from "../../../fields/Button";
const { addToCart, decrementCartItem, removeFromCart } = Users.PostRequests;
class MobileCartBottom extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isTabletOrMobile: window.innerWidth <= 994,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  handleResize = () => {
    this.setState({
      isTabletOrMobile: window.innerWidth <= 994,
    });
  };
  calculateSubtotal(product) {
    return CalculatePrice(product) * product.quantity;
  }
  calculateTotal() {
    const { cart_items = [] } = this.props.cart;
    const totalItemPrice = cart_items.reduce((total, product) => {
      return total + this.calculateSubtotal(product);
    }, 0);
    return FormatCurrencyToVND(totalItemPrice);
  }

  renderOrderCartItems() {
    const { cart_items = [] } = this.props.cart;
    return cart_items.map((product, index) => {
      const { selectedModifiers = [] } = product;
      return (
        <div className={Style.itemRow} key={index}>
          <div className={Style.itemThumb}>
            <img src={product.thumbnail_image[0] || DEFAULT_IMAGE_ITEM} alt={product.item_name} />
          </div>
          <div className={Style.itemName}>
            <Link
              className={Style.cartOrderItemName}
              to={{
                state: { singleProductData: product },
                pathname: `product/${FormatUrl(product.item_name)}?_id=${
                  product._id
                }`,
              }}
            >
              {product.item_name}
            </Link>
          </div>
          <div className={Style.itemPrice}>
            {FormatCurrencyToVND(CalculatePrice(product))}
          </div>
          <div
            className={Style.itemDecrement}
            onClick={() => this.props.decrementCartItem(index)}
          >
            -
          </div>
          <div className={Style.itemQuantity}>{product.quantity}</div>
          <div
            className={Style.itemIncrement}
            onClick={() => this.props.addToCart(product, 1, selectedModifiers)}
          >
            +
          </div>
          <div
            className={Style.itemRemove}
            onClick={() => this.props.removeFromCart(index)}
          >
            Xoá
          </div>
        </div>
      );
    });
  }
  render() {
    const { isTabletOrMobile } = this.state;
    const { cart_items = [] } = this.props.cart;
    const countCartItem = Object.values(cart_items).reduce(
      (prevItem, currentItem) => {
        return prevItem + currentItem.quantity;
      },
      0
    );
    return (
      <div className={`${Style.stickyRight} position-sticky mobile-bottom-bar`}>
        <div
          className={`${Style.orderCart} accordion bottom-bar ${
            isTabletOrMobile ? "fixed-bottom" : ""
          } text-white`}
          id={`${Style.cartSidebar} accordionExample`}
        >
          <div className="accordion-item">
            <div
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
              id="headingOne"
              className={` ${Style.cartMobile}`}
            >
              <span className={Style.countItemQuantity}>
                {countCartItem} Món
              </span>
              <span className={Style.mobileTotalPrice}>
                {this.calculateTotal()}
              </span>
              <span className={Style.closeBtn}>Đóng</span>
            </div>
            <div
              id="collapseOne"
              className={`accordion-collapse collapse ${
                isTabletOrMobile ? "" : "show"
              }`}
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <div className={Style.OrderCartItemHeader}>
                  <div className={Style.OrderCartItem}>
                    {this.renderOrderCartItems()}
                  </div>
                </div>
                <div className={Style.OrderCartItemBottom}>
                  <div className={Style.subTotal}>
                    <p>Tạm tính:</p>
                    <span>{this.calculateTotal()}</span>
                  </div>
                  <div className={Style.TotalBill}>
                    <p>Tổng cộng:</p>
                    <span>{this.calculateTotal()}</span>
                  </div>
                  <div className={Style.CheckoutBtnBox}>
                    <Link to="/checkout" className={Style.CheckoutButtonLink}>
                      <OvButton
                        name="checkout button"
                        className={Style.CheckoutButton}
                      >
                        Thanh toán
                      </OvButton>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    cart: state.cart,
  };
};
const mapDispatchToProps = {
  addToCart,
  decrementCartItem,
  removeFromCart,
};
export default connect(mapStateToProps, mapDispatchToProps)(MobileCartBottom);
