import React, { Component } from "react";
import Style from "./style.module.scss";
import { Link, withRouter } from "react-router-dom";
import { GrCart } from "react-icons/gr";
// import { FiSearch } from "react-icons/fi";
import { connect } from "react-redux";
import OvButton from "../../../fields/Button";
import {
  CalculatePrice,
  DEFAULT_IMAGE_ITEM,
  FormatCurrencyToVND,
  FormatUrl,
} from "../../../lib/Functions";
import { Users } from "../../../lib/Services";
import { NAVBAR_LINK_ITEM } from "../_constants";
import NavigationBar from "./NavigationBar";
const { addToCart, removeFromCart, decrementCartItem, handleGetCartItems } =
  Users.PostRequests;
const { GetAllCategories } = Users.GetRequests;
class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      paragraphs: [
        "Chất lượng đảm bảo và phong cách phục vụ chuyên nghiệp",
        "Thời gian hoạt động: 09:00 - 22:00 mỗi ngày",
        "Giao hàng nhanh",
      ],
    };
  }

  componentDidMount() {
    this.props.GetAllCategories();
    this.props.handleGetCartItems();
    this.interval = setInterval(this.changeParagraph, 4000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  changeParagraph = () => {
    this.setState((prevState) => ({
      currentIndex: (prevState.currentIndex + 1) % prevState.paragraphs.length,
    }));
  };

  calculateSubtotal(product) {
    return CalculatePrice(product) * product.quantity;
  }

  calculateTotal() {
    const { cart_items = [] } = this.props.cart;
    const totalItemPrice = cart_items.reduce((total, product) => {
      return total + this.calculateSubtotal(product);
    }, 0);
    return FormatCurrencyToVND(totalItemPrice);
  }
  renderOrderCartItems() {
    const { location } = this.props;
    // const isProductRoute = location.pathname.startsWith("/product");
    const { cart_items = [] } = this.props.cart;
    return cart_items.map((product, index) => {
      const productPath = `/product/${FormatUrl(product.item_name)}?_id=${
        product._id
      }`;
      const handleLinkClick = (e) => {
        const currentPath = location.pathname;
        if (currentPath !== productPath) {
          // Redirect to the new path
          this.props.history.push({
            pathname: productPath,
            state: { singleProductData: product },
          });
          e.preventDefault();
        }
      };
      const { selectedModifiers = [] } = product;
      const isAvailableImage =
        Array(product.thumbnail_image).length > 0 &&
        product.thumbnail_image !== undefined
          ? product.thumbnail_image[0]
          : DEFAULT_IMAGE_ITEM;
      return (
        <div className={Style.itemRow} key={index}>
          <div className={Style.itemThumb}>
            <img src={isAvailableImage} alt={product.item_name} />
          </div>
          <div className={Style.itemName}>
            <Link
              className={Style.cartOrderItemName}
              to={{
                pathname: `/product/${FormatUrl(product.item_name)}?_id=${
                  product._id
                }`,
                state: { singleProductData: product },
              }}
              onClick={handleLinkClick}
            >
              {product.item_name}
            </Link>
          </div>
          <div className={Style.itemModifiers}>
            {selectedModifiers.length > 0 && (
              <div className={Style.modifiers_item}>
                <p>Món thêm:</p>
                {selectedModifiers.map((modifier, index) => (
                  <div key={modifier.modifier_key}>
                    {index > 0 && ", "} {modifier.modifier_name}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className={Style.itemPrice}>
            {FormatCurrencyToVND(CalculatePrice(product))}
          </div>
          <div
            className={Style.itemDecrement}
            onClick={() => this.props.decrementCartItem(index)}
          >
            -
          </div>
          <div className={Style.itemQuantity}>{product.quantity}</div>
          <div
            className={Style.itemIncrement}
            onClick={() => this.props.addToCart(product, 1, selectedModifiers)}
          >
            +
          </div>
          <div
            className={Style.itemRemove}
            onClick={() => this.props.removeFromCart(index)}
          >
            Xoá
          </div>
        </div>
      );
    });
  }

  render() {
    const { currentIndex, paragraphs } = this.state;
    const { cart_items = [] } = this.props.cart;
    const { allCategories = [] } = this.props;
    const countCartItem = Object.values(cart_items).reduce(
      (prevItem, currentItem) => {
        return prevItem + currentItem.quantity;
      },
      0
    );
    const mainPageCategories = Object.entries(allCategories)
      .filter(
        ([categoryKey, category]) =>
          category.is_on_main_page && category.status === "visible"
      )
      .map(([categoryKey, category]) => ({ name: category.name, categoryKey }));

    const mergedLinks = [
      ...NAVBAR_LINK_ITEM.slice(0, 1),
      ...mainPageCategories.slice(0, 4),
      ...NAVBAR_LINK_ITEM.slice(1),
    ];
    return (
      <div className={`${Style.Navbar_Container} container`}>
        <div className={Style.TopBar}>
          <div className={Style.left_menu}>
            <NavigationBar mergedLinks={mergedLinks} />
          </div>
          <div className={Style.left}>
            <p className={Style.fadeIn} key={currentIndex}>
              {paragraphs[currentIndex]}
            </p>
          </div>
          <Link to={"/"} className={Style.center}>
            <img src="/images/logo_main.png" alt="an vat logo" />
          </Link>
          <div className={Style.right}>
            <div className={Style.Hotline}>
              <img src="/images/shipper.png" alt="An vat delivery" />
              <div className={Style.HotLine_Info}>
                <p>Hotline hỗ trợ</p>
                <a href="tel:0772454342">0772454342</a>
              </div>
            </div>
            <div className={Style.Cart}>
              <span
                className={`${Style.BtnCanvas}`}
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight"
                aria-controls="offcanvasRight"
              >
                <small>{countCartItem}</small>
                <GrCart />
              </span>
              <div
                className={`${Style.offcanvas} offcanvas offcanvas-end`}
                tabIndex={-1}
                id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel"
              >
                <div className="offcanvas-header">
                  <h5 id="offcanvasRightLabel">
                    Giỏ hàng: {countCartItem} sản phẩm
                  </h5>
                  <button
                    type="button"
                    className="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  />
                </div>
                <div className="offcanvas-body">
                  <div className={Style.orderCartItem}>
                    {this.renderOrderCartItems()}
                  </div>
                  <div className={Style.OrderCartItemBottom}>
                    <div className={Style.subTotal}>
                      <p>Tạm tính:</p>
                      <span>{this.calculateTotal()}</span>
                    </div>
                    <div className={Style.TotalBill}>
                      <p>Tổng cộng:</p>
                      <span>{this.calculateTotal()}</span>
                    </div>

                    <Link to="/checkout" className={Style.CheckoutButtonLink}>
                      <OvButton
                        name="checkout button"
                        className={Style.CheckoutButton}
                      >
                        Thanh toán
                      </OvButton>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={Style.NavBar}>
          <ul className={Style.Menu}>
            {mergedLinks.map((el, index) => {
              const { name, categoryKey, link } = el;
              return (
                <li key={index}>
                  <Link to={link ? link : `/categories/${categoryKey}`}>
                    {name}
                  </Link>
                </li>
              );
            })}
          </ul>
          {/* <div className={`${Style.search} mx-5`}>
            <input type="text" placeholder="Tìm món ăn..." />
            <FiSearch className={Style.search_icon} />
          </div> */}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    allCategories: state.AllCategoryReducer.allCategories,
  };
};
const mapDispatchToProps = {
  GetAllCategories,
  decrementCartItem,
  addToCart,
  removeFromCart,
  handleGetCartItems,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar));
