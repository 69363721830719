import React, { Component } from "react";
import Style from "./view.module.scss";
import { withRouter } from "react-router-dom";
import OvButton from "../fields/Button/index";
class NotFound extends Component {
  render() {
    return (
      <div className={`${Style.NotFoundContainer} container`}>
        <img src="/images/404.png" alt="404 not found" />
        <h2>Lỗi không tìm thấy trang</h2>
        <p>
          Có vẻ như các trang mà bạn đang cố gắng tiếp cận không tồn tại nữa
          hoặc có thể nó vừa di chuyển.
        </p>
        <OvButton
          name="go to index page"
          className={`${Style.NotFoundButton} btn btn-success`}
          onClick={() => this.props.history.push("/")}
        >
          Trang chủ
        </OvButton>
      </div>
    );
  }
}

export default withRouter(NotFound);
