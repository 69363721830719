// BUTI DINERS, INC. All right Reserved ©

import React from "react";
import PropTypes from "prop-types";

// Convert Raw HTML TO REACT
const RawHTML = ({ children, tag = "div", nl2br = true, ...rest }) =>
  React.createElement(tag, {
    dangerouslySetInnerHTML: {
      __html: nl2br ? children && children.replace(/\n/g, "<br />") : children,
    },
    ...rest,
  });

RawHTML.propTypes = {
  children: PropTypes.string,
  nl2br: PropTypes.bool,
  tag: PropTypes.string,
};

// Format Date for post
const _formatDate = (pubDate) =>
  new Date(pubDate).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

// Extract the longest text content within <p> tags

const _getShortParagraph = (description) => {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = description;

  const paragraphs = tempElement.querySelectorAll("p");

  let longestText = "";
  let maxLength = 0;

  paragraphs.forEach((paragraph) => {
    const text = paragraph.textContent;
    if (text.length > maxLength) {
      maxLength = text.length;
      longestText = text;
    }
  });

  return longestText;
};

const _readTime = (content) => {
  const WPS = 275 / 60;

  var images = 0;
  const regex = /\w/;

  let words = content.split(" ").filter((word) => {
    if (word.includes("<img")) {
      images += 1;
    }
    return regex.test(word);
  }).length;

  var imageAdjust = images * 4;
  var imageSecs = 0;
  var imageFactor = 12;

  while (images) {
    imageSecs += imageFactor;
    if (imageFactor > 3) {
      imageFactor -= 1;
    }
    images -= 1;
  }

  const minutes = Math.ceil(((words - imageAdjust) / WPS + imageSecs) / 60);

  return minutes;
};

export {
  _getShortParagraph,
  _formatDate,
  RawHTML,
  _readTime,
};
