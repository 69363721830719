export const GET_ALL_ITEM = "GET_ALL_ITEM";
export const GET_ALL_CATEGORY = "GET_ALL_CATEGORY";
export const GET_ALL_MODIFIERS = "GET_ALL_MODIFIERS";
export const GET_ALL_GROUP_MODIFIERS = "GET_ALL_GROUP_MODIFIERS";

// SINGLE ITEM
export const GET_SINGLE_ITEM_SUCCESS = "GET_ITEM_SUCCESS";
export const GET_SINGLE_REQUEST = "GET_SINGLE_REQUEST";
export const GET_SINGLE_ITEM_FAILED = "GET_SINGLE_ITEM_FAILED";

export const GET_MODIFY_GROUP_REQUEST = "GET_MODIFY_GROUP_REQUEST";
export const GET_MODIFY_GROUP_SUCCESS = "GET_MODIFY_GROUP_SUCCESS";
export const GET_MODIFY_GROUP_FAILED = "GET_MODIFY_GROUP_FAILED";
